import { isEmpty } from '@/utils';
import {
  Autocomplete,
  Chip,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
} from '@mui/material';
import { Fragment, useState } from 'react';

export function ChipField({
  input: { onChange, value },
  meta,
  categories = {},
  disabled,
  ...props
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  function handleAvatarClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleChange(event, value) {
    const [reference, ...existingValues] = value.slice().reverse();

    if (value.length === 0 || typeof reference === 'object') {
      onChange(value);
    } else {
      onChange([
        ...existingValues.reverse(),
        { category: 'Business', reference },
      ]);
    }
  }

  const handleCategoryClick = (category) => () => {
    onChange(
      value.map((chip) =>
        chip.reference === anchorEl.dataset.reference
          ? {
              category,
              reference: anchorEl.dataset.reference,
            }
          : chip,
      ),
    );
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <Autocomplete
        fullWidth
        size="small"
        multiple
        freeSolo
        value={value || []}
        options={[]}
        disabled={disabled}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField
            {...params}
            {...props}
            helperText={meta.error ?? meta.submitError}
            error={meta.invalid}
          />
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => {
            const { key, ...props } = getTagProps({ index });

            return (
              <Chip
                key={key}
                data-reference={option.reference}
                label={option.reference}
                {...props}
                icon={
                  !isEmpty(categories) && (
                    <Chip
                      color="primary"
                      label={categories[option.category]}
                      size="small"
                    />
                  )
                }
                onClick={!isEmpty(categories) && !disabled && handleAvatarClick}
              />
            );
          })
        }
      />
      {categories && (
        <Menu
          id="category-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {Object.entries(categories).map(([value, label]) => (
            <MenuItem key={value} onClick={handleCategoryClick(value)}>
              <ListItemText primary={label} />
            </MenuItem>
          ))}
        </Menu>
      )}
    </Fragment>
  );
}
