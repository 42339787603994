import { FETCH_LIVE_LOCATION } from '@/actions/types';
import { TagControl } from '@/components/controls';
import {
  useDocumentTitle,
  useLocationTypes,
  useOptionLookup,
  useOptionValues,
  usePrevious,
} from '@/hooks';
import { startCase } from '@/utils';
import {
  Edit as EditIcon,
  LocationSearching as FollowIcon,
} from '@mui/icons-material';
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Stack,
  SvgIcon,
  Table,
  TableBody,
  Tooltip,
  Typography,
} from '@mui/material';
import { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getTypesInLocation } from '../utilities';
import { ItemRows } from './ItemRows';
import { PeopleLiveListItem } from './PeopleLiveListItem';
import { VehicleLiveListItem } from './VehicleLiveListItem';

// example object
// $type: "add"
// areas: Array(0)
// boundary:
// $reql_type$: "GEOMETRY"
// coordinates: [Array(6)]
// type: "Polygon"
// code: "Braunstone"
// subtype: ""
// name: "Braunstone"
// searchString: "+[object object]+braunstone++braunstone+police station+add+true"
// tagChanged: true
// type: "Police Station"

export function LocationLiveItem({
  item,
  onSubItemClick,
  onSubItemHover,
  onFollowToggle,
  followedIdsByType,
  hoveredId,
}) {
  useDocumentTitle(
    ['IR3', 'Live', 'Locations', item?.name].filter(Boolean).join(' | '),
  );
  const hideFollow = !item.boundary;
  const state = useSelector((state) => state.live);
  const locationRecord = useSelector(
    (state) => state.live.locationRecordsById[item.id],
  );
  const layerVisibilities = useSelector(
    (state) => state.live.layerVisibilities,
  );
  const locationGroups = useOptionValues('locationGroup');
  const groupTypes = useOptionLookup('groupType');
  const { data: locationTypes } = useLocationTypes();
  const showStale = layerVisibilities.stale;
  const resourcesInLocation = getTypesInLocation(
    state.people,
    item.code,
    'people',
    showStale,
  );
  const vehiclesInLocation = getTypesInLocation(
    state.vehicles,
    item.code,
    'vehicles',
    showStale,
  );

  const typesInLocation = { ...resourcesInLocation, ...vehiclesInLocation };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const prevId = usePrevious(item.id);
  useEffect(() => {
    if (prevId !== item.id && !locationRecord) {
      dispatch({
        type: FETCH_LIVE_LOCATION,
        payload: item.id,
      });
    }
  }, [dispatch, item.id, prevId, locationRecord]);

  const sections = {
    details: [
      { label: 'Type', value: item.type },
      { label: 'Subtype', value: item.subtype },
    ],
    occupants: [
      {
        label: 'People',
        value: typesInLocation.people.length,
      },
      {
        label: 'Vehicles',
        value: typesInLocation.vehicles.length,
      },
    ],
    groups: locationRecord?.groupAncestors?.map((group) => ({
      label: groupTypes[group.type],
      value: group.name,
    })),
    categories: Object.entries(item.groups ?? []).map(([type, values]) => ({
      label:
        type in locationGroups ? locationGroups[type].label : startCase(type),
      value: values
        .map(
          (value) =>
            locationGroups[type]?.values.find((entry) => entry.value === value)
              ?.label ?? value,
        )
        .join(', '),
    })),
  };

  const type = 'locations';
  const following = followedIdsByType?.[type]?.[item.id];
  const subtype =
    Object.keys(locationTypes).find(
      (type) => locationTypes[type].value === item.type,
    ) || item.type?.toLowerCase() + 's';
  const link = `/${type}/${subtype}/${item.id ?? item.code}`;

  const typeToListItemMap = {
    vehicles: VehicleLiveListItem,
    people: PeopleLiveListItem,
  };

  const orderedTypes = {
    people: 'People',
    vehicles: 'Vehicles',
  };

  return (
    <Card sx={{ m: 1 }}>
      <CardHeader
        avatar={
          <Avatar src={locationRecord?.picture}>
            <SvgIcon>
              <path d={locationTypes[subtype]?.d} />
            </SvgIcon>
          </Avatar>
        }
        title={item.name}
        subheader={item.code}
        action={
          <Stack direction="row" alignItems="center">
            <Tooltip title="Edit">
              <IconButton onClick={() => navigate(link)}>
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            {!hideFollow && (
              <Tooltip title="Toggle follow">
                <IconButton onClick={() => onFollowToggle(type, item.id)}>
                  <FollowIcon
                    fontSize="small"
                    color={following ? 'primary' : 'inherit'}
                  />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        }
      />
      <CardContent>
        <TagControl
          live
          item={item}
          type="locations"
          sx={{ pb: 2 }}
          label="Tags"
        />
        {Object.keys(sections)
          .filter(
            (key) =>
              sections[key] &&
              (!Array.isArray(sections[key]) || sections[key].length > 0),
          )
          .map((key) => (
            <Fragment key={key}>
              <Typography variant="subtitle2" color="textSecondary">
                {startCase(key)}
              </Typography>
              {Array.isArray(sections[key]) ? (
                <Table size="small" sx={{ mt: 1, mb: 2 }}>
                  <TableBody>{ItemRows(sections[key], item)}</TableBody>
                </Table>
              ) : (
                sections[key]
              )}
            </Fragment>
          ))}
        {Object.keys(typesInLocation).map((type) => (
          <Fragment key={type}>
            <Typography variant="subtitle2" color="textSecondary">
              {typesInLocation[type].length > 0 && orderedTypes[type]}
            </Typography>
            {typesInLocation[type].map((obj) => {
              const ListComponent = typeToListItemMap[type];
              return (
                <ListComponent
                  key={obj.id}
                  onClick={onSubItemClick}
                  highlighted={hoveredId === obj.id}
                  onHoverItem={onSubItemHover}
                  onFollowToggle={onFollowToggle}
                  followedIdsByType={followedIdsByType}
                  item={obj}
                />
              );
            })}
          </Fragment>
        ))}
      </CardContent>
    </Card>
  );

  /*return (
    <Fragment>
      <div className={{ root: { flexGrow: 1 } }}>
        <Grid container style={{ padding: '8px' }}>
          <Grid item xs={12}>
            <h4>Resource Status</h4>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.cardText}>
              Code: {item.code}
            </Typography>
            <Typography className={classes.cardText}>
              Type: {item.type}
            </Typography>
            <Typography className={classes.cardText}>
              Subype: {item.subtype}
            </Typography>
            <Typography className={classes.cardText}>
              District: {item.district}
            </Typography>
            <Typography className={classes.cardText}>Areas: </Typography>
            <Grid style={{ paddingLeft: '8px' }}>
              {item.areas &&
                item.areas.length > 0 &&
                item.areas.map((area, i) => (
                  <Typography className={classes.cardText} key={i}>
                    {area.name}
                  </Typography>
                ))}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Fragment>
  );*/
}
