import { CustomTooltip, Parameters } from '@/components/controls';
import { useDocumentTitle } from '@/hooks';
import { downloadCSV, getFilenameForDownload } from '@/utils';
import { GetApp as GetAppIcon } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { blue } from '@mui/material/colors';
import { useAtom } from 'jotai';
import {
  Bar,
  BarChart,
  Tooltip as ChartTooltip,
  Label,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import { useAverageOccupantsByHour } from './useAverageOccupantsByHour';
import { formatLabel, formatValue, stateAtom } from './utils';

export function HourlyInLocations() {
  useDocumentTitle('IR3 | Hourly in Location');
  const [{ query, parameters }, setState] = useAtom(stateAtom);
  const { data, cancel, isLoading, isFetching } = useAverageOccupantsByHour({
    query,
  });

  function handleFetch(event, query) {
    setState((state) => ({ ...state, query }));
  }

  function handleCancel() {
    cancel();
  }

  function handleParametersChange(parameters) {
    setState((state) => ({
      ...state,
      parameters,
    }));
  }

  function handleDownloadClick() {
    const headers = [
      { label: 'Hour', key: 'hour' },
      { label: 'Average Occupants', key: 'average' },
    ];
    const filename = getFilenameForDownload(
      'hourly-in-locations',
      'csv',
      parameters?.startTime,
      parameters?.endTime,
    );

    downloadCSV(data, filename, headers);
  }

  return (
    <Box
      sx={{
        display: 'flex',
        height: 'calc(100vh - 48px)',
      }}
    >
      <Parameters
        collection="intersections"
        onFetch={handleFetch}
        onCancel={handleCancel}
        isFetching={isLoading || isFetching}
        value={parameters}
        onChange={handleParametersChange}
        sx={{ mt: 1, width: 264 }}
        vehicle
        location
        dateOnly
      />
      <Box
        sx={{
          flex: 1,
          height: 'calc(100vh - 48px)',
          overflowY: 'auto',
          pt: 1.5,
          pr: 1,
        }}
      >
        <Stack spacing={1}>
          <Stack
            direction="row"
            spacing={1}
            sx={{ justifyContent: 'flex-end' }}
          >
            <Tooltip title="Download data">
              <Box component="span">
                <IconButton
                  disabled={data.length === 0}
                  onClick={handleDownloadClick}
                >
                  <GetAppIcon />
                </IconButton>
              </Box>
            </Tooltip>
          </Stack>
          <Paper sx={{ p: 2, minWidth: 240, fontSize: 12 }}>
            <Stack
              direction="row"
              sx={{ pb: 1, justifyContent: 'space-between' }}
            >
              <Typography
                variant="subtitle2"
                color="text.secondary"
                gutterBottom
              >
                Occupants
              </Typography>
              <Typography variant="caption" color="text.secondary">
                Average number of vehicles in the selected locations by hour
              </Typography>
            </Stack>
            <ResponsiveContainer width="99%" height={400}>
              <BarChart
                data={data}
                margin={{ top: 8, right: 16, left: 0, bottom: 32 }}
              >
                <XAxis dataKey="hour" interval={0} tickFormatter={formatLabel}>
                  <Label value="Hour" position="bottom" offset={36} />
                </XAxis>
                <YAxis>
                  <Label
                    value="Average"
                    angle={-90}
                    position="left"
                    offset={-24}
                  />
                </YAxis>
                <ChartTooltip
                  cursor={false}
                  content={
                    <CustomTooltip
                      unit="vehicles"
                      labelFormatter={formatLabel}
                      valueFormatter={formatValue}
                    />
                  }
                  wrapperStyle={{ pointerEvents: 'auto' }}
                />
                <Bar
                  type="monotone"
                  dataKey="average"
                  name="Average Occupants"
                  stroke={blue[500]}
                  fill={blue[500]}
                />
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Stack>
      </Box>
    </Box>
  );
}
