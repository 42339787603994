import { highlightsStateAtom } from '@/atoms';
import { useDocumentTitle, useGroups } from '@/hooks';
import { getGroupType } from '@/utils';
import { Autocomplete, Stack, TextField } from '@mui/material';
import { useAtom } from 'jotai';
import { CurrentObjectivesCard } from './CurrentObjectivesCard';
import { DailyActivityCard } from './DailyActivityCard';
import { IncidentsByGradeCard } from './IncidentsByGradeCard';
import { ResponseTimeByHourCard } from './ResponseTimeByHourCard';
import { TotalIncidentsCard } from './TotalIncidentsCard';
import { UncheckedVehiclesCard } from './UncheckedVehiclesCard';

export function Sector() {
  useDocumentTitle('IR3 | Highlights | Sector');
  const [{ sector }, setState] = useAtom(highlightsStateAtom);
  const groupType = getGroupType('SECTOR');
  const subgroupType = getGroupType('TEAM');
  const { data: groups } = useGroups({
    types: [getGroupType(groupType)],
  });

  function handleSectorChange(event, value) {
    setState((state) => ({ ...state, sector: value }));
  }

  return (
    <Stack sx={{ p: 1 }} spacing={1}>
      <Stack
        direction="row"
        flexDirection="row-reverse"
        sx={{ width: 1, pt: 0.5 }}
      >
        <Autocomplete
          size="small"
          value={sector}
          onChange={handleSectorChange}
          options={groups.map(({ code, name }) => ({
            value: code,
            label: name,
          }))}
          renderInput={(params) => <TextField label="Sector" {...params} />}
          isOptionEqualToValue={(option, value) =>
            (option.value ?? option) === (value.value ?? value)
          }
          renderOption={(props, option) => (
            <li {...props} key={option.value}>
              {option.label}
            </li>
          )}
          sx={{ minWidth: 200 }}
        />
      </Stack>
      <Stack spacing={1} direction="row" alignItems="baseline">
        <Stack spacing={1}>
          <CurrentObjectivesCard
            groupType={groupType}
            groupCode={sector?.value}
          />
          <TotalIncidentsCard
            groupType={groupType}
            groupCode={sector?.value}
            subgroupType="WARD"
          />
          <UncheckedVehiclesCard groupCode={sector?.value} />
        </Stack>
        <Stack spacing={1} sx={{ flexGrow: 1 }}>
          <DailyActivityCard
            groupCode={sector?.value}
            subgroupType={subgroupType}
          />
          <IncidentsByGradeCard
            groupType={groupType}
            groupCode={sector?.value}
          />
          <ResponseTimeByHourCard
            groupType={groupType}
            groupCode={sector?.value}
            subgroupType="WARD"
          />
        </Stack>
      </Stack>
    </Stack>
  );
}
