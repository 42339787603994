import { TagControl } from '@/components/controls';
import { useDocumentTitle } from '@/hooks';
import {
  Edit as EditIcon,
  LocationSearching as FollowIcon,
} from '@mui/icons-material';
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Table,
  TableBody,
  Tooltip,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { ItemAvatar } from './ItemAvatar';
import { ItemRows } from './ItemRows';

export function RadioLiveItem({ item, onFollowToggle, followedIdsByType }) {
  useDocumentTitle(
    ['IR3', 'Live', 'Radios', item?.ssi].filter(Boolean).join(' | '),
  );
  const hideFollow = !item.lastPosition;
  const type = 'radios';
  const following = followedIdsByType?.[type]?.[item.id];

  const navigate = useNavigate();

  const sections = {
    details: [
      { label: 'SSI', value: item.ssi },
      { label: 'Type', value: item.type },
      {
        label: 'Last poll time',
        value: item.lastRadioPoll?.time
          ? format(new Date(item.lastRadioPoll.time), 'dd/MM/yyyy HH:mm:ss')
          : '-',
      },
    ],
  };

  const title = item.ssi;
  const link = `/resources/${type}/${item.id}`;

  return (
    <Card sx={{ m: 1 }}>
      <CardHeader
        avatar={<ItemAvatar item={item} type="radios" />}
        title={title}
        action={
          <Fragment>
            <Tooltip title="Edit">
              <IconButton onClick={() => navigate(link)}>
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            {!hideFollow && (
              <Tooltip title={following ? 'Stop following' : 'Follow'}>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    onFollowToggle('radios', item.id);
                  }}
                >
                  <FollowIcon
                    titleAccess="Follow"
                    fontSize="small"
                    color={following ? 'primary' : 'disabled'}
                  />
                </IconButton>
              </Tooltip>
            )}
          </Fragment>
        }
      />
      <CardContent>
        <TagControl live item={item} type={type} sx={{ py: 2 }} label="Tags" />
        {Object.keys(sections)
          .filter((key) => sections[key] && sections[key].length > 0)
          .map((key) => (
            <Fragment key={key}>
              <Typography variant="subtitle2" color="textSecondary">
                {key}
              </Typography>
              <Table size="small" sx={{ mt: 1, mb: 2 }}>
                <TableBody>{ItemRows(sections[key], item)}</TableBody>
              </Table>
            </Fragment>
          ))}
      </CardContent>
    </Card>
  );
}
