import { api } from '@/apis';
import { match } from '@/components/retrospective/constants';
import {
  apiRootUrl,
  authenticationRootUrl,
  authenticationScheme,
  clientId,
  dioStates,
  groupTypeAliases,
  liveOptions,
  maxUploadSize,
  ordnanceSurveyKey,
  resource,
  styles,
  tenantId,
  useDallasKeys,
  useRestricted,
} from '@/utils/config';
import { keyframes } from '@emotion/react';
import rewind from '@mapbox/geojson-rewind';
import { darken, lighten } from '@mui/material';
import {
  amber,
  blue,
  cyan,
  green,
  grey,
  indigo,
  orange,
  purple,
  red,
  yellow,
} from '@mui/material/colors';
import {
  addDays,
  addHours,
  addMonths,
  addWeeks,
  differenceInDays,
  differenceInHours,
  differenceInSeconds,
  endOfDay,
  endOfMonth,
  endOfWeek,
  format,
  startOfDay,
  startOfHour,
  startOfMonth,
  startOfWeek,
  subDays,
  subMinutes,
  subMonths,
  subWeeks,
} from 'date-fns';
import humanizeDuration from 'humanize-duration';
import ky from 'ky';
import _ from 'lodash';
import { User } from 'oidc-client-ts';
import Papa from 'papaparse';
import proj4 from 'proj4';

const {
  stalePersonThreshold,
  staleVehicleIgnitionOnThreshold,
  staleVehicleIgnitionOffThreshold,
} = liveOptions;

export function getUser() {
  const oidcStorage = localStorage.getItem(
    `oidc.user:${authenticationRootUrl}/${tenantId}:${clientId}`,
  );
  if (!oidcStorage) {
    return null;
  }

  return User.fromStorageString(oidcStorage);
}

export function getHeaders() {
  const user = getUser();

  return { authorization: `Bearer ${user?.access_token}` };
}

export async function doesIdExist(type, id) {
  const response = await api
    .get(type, {
      searchParams: encodeParams({ query: { _id: id } }),
    })
    .json();

  if (response.length) {
    return true;
  } else {
    return false;
  }
}

export async function doesTitleExistForUser(title) {
  const user = getUser();

  const response = await api
    .get('retrospectives', {
      searchParams: encodeParams({
        query: { title, 'created.userId': user?.profile?.unique_name },
        projection: { identifier: true, created: true },
      }),
    })
    .json();

  return response && response.length > 0;
}

export async function userQueryTitleDoesNotExist(
  _id,
  title,
  collection,
  userId,
) {
  const response = await api
    .get('userQueries', {
      searchParams: encodeParams({
        query: {
          ...(_id ? { _id: { $ne: _id } } : {}),
          title,
          collection,
          'created.userId': userId,
        },
      }),
    })
    .json();

  if (response.length) {
    return 'Exists';
  } else {
    return true;
  }
}

export async function getNewIdentifier(type) {
  const number = await api.get(`autoNumber/${type.toUpperCase()}`).text();

  return `${type.toUpperCase()}${number}`;
}

export async function log(action, dataType, parameters) {
  await api.post('audits', {
    json: {
      action,
      dataType,
      parameters,
    },
  });
}

function sub(date, amount, unit) {
  switch (unit) {
    case 'days':
      return subDays(date, amount);
    case 'weeks':
      return subWeeks(date, amount);
    case 'months':
      return subMonths(date, amount);
    default:
      return date - amount;
  }
}

function add(date, amount, unit) {
  switch (unit) {
    case 'days':
      return addDays(date, amount);
    case 'weeks':
      return addWeeks(date, amount);
    case 'months':
      return addMonths(date, amount);
    default:
      return date + amount;
  }
}

function startOf(date, unit) {
  switch (unit) {
    case 'days':
      return startOfDay(date);
    case 'weeks':
      return startOfWeek(date);
    case 'months':
      return startOfMonth(date);
    default:
      return date;
  }
}

function endOf(date, unit) {
  switch (unit) {
    case 'days':
      return endOfDay(date);
    case 'weeks':
      return endOfWeek(date);
    case 'months':
      return endOfMonth(date);
    default:
      return date;
  }
}

export function getRelativeTimePeriod(timePeriod) {
  if (timePeriod.isRetrospective) {
    const endTime = timePeriod.includeCurrent
      ? endOf(new Date(), timePeriod.unit)
      : startOf(new Date(), timePeriod.unit);
    return {
      startTime: sub(new Date(endTime), timePeriod.amount, timePeriod.unit),
      endTime,
    };
  } else {
    const startTime = timePeriod.includeCurrent
      ? startOf(new Date(), timePeriod.unit)
      : endOf(new Date(), timePeriod.unit);
    return {
      startTime,
      endTime: add(new Date(startTime), timePeriod.amount, timePeriod.unit),
    };
  }
}

export function getPrimaryLocation(locations) {
  const locationTypeSequence = new Map([
    ['Police Station', 0],
    ['Base', 1],
    ['Workshop', 2],
    ['Ward', 3],
  ]);

  if ((locations || []).length === 0) {
    return {
      name: 'Elsewhere',
      type: 'None',
    };
  } else {
    return locations
      .map((location) => {
        return {
          name: location.name,
          type: location.type,
          sequence: locationTypeSequence.get(location.type) || 100,
        };
      })
      .sort((a, b) => {
        return a.sequence - b.sequence;
      })[0];
  }
}

export async function getObjectiveBoundary(objective) {
  switch (objective.boundaryType) {
    case 'Location': {
      const location = await api
        .get(`locations/${objective.boundaryIdentifier}`, {
          searchParams: encodeParams({
            projection: { boundary: true },
          }),
        })
        .json();
      return location.boundary;
    }
    default:
      return objective.boundary;
  }
}

export async function getObjectiveWards(objective) {
  const boundary = await getObjectiveBoundary(objective);
  const response = await api.get('locations', {
    searchParams: encodeParams({
      query: {
        type: 'Ward',
        boundary: {
          $geoIntersects: {
            $geometry: boundary,
          },
        },
      },
      projection: { code: true },
    }).json(),
  });

  return response.map((ward) => ward.code);
}

export const eventsPersonTableHeaders = [
  {
    label: 'Forenames',
    key: 'person.forenames',
    type: 'text',
    filter: true,
  },
  {
    label: 'Surname',
    key: 'person.surname',
    type: 'text',
    filter: true,
  },
  {
    label: 'Role',
    key: 'person.role',
    type: 'text',
    filter: true,
  },
  {
    label: 'Collar Number',
    key: 'person.collarNumber',
    type: 'text',
    filter: true,
  },
  {
    label: 'Rank',
    key: 'person.rank.code',
    type: 'text',
    filter: true,
  },
];

export const shortPersonHeaders = [
  { label: 'Name', key: 'name', type: 'text' },
  { label: 'Role', key: 'personRole', type: 'text' },
  { label: 'Collar Number', key: 'collarNumber', type: 'text' },
];

export const shortVehicleHeaders = [
  { label: 'Registration', key: 'registrationNumber', type: 'text' },
  { label: 'Fleet Number', key: 'fleetNumber', type: 'text' },
  { label: 'Vehicle Role', key: 'vehicleRole', type: 'text' },
];

export const longPersonVehicleHeaders = [
  {
    label: 'Registration',
    key: 'registrationNumber',
    type: 'text',
  },
  { label: 'Fleet Number', key: 'fleetNumber', type: 'text' },
  { label: 'Vehicle Role', key: 'vehicleRole', type: 'text' },
  { label: 'Type', key: 'type', type: 'text' },
  { label: 'Name', key: 'name', type: 'text' },
  { label: 'Role', key: 'personRole', type: 'text' },
  { label: 'Collar Number', key: 'collarNumber', type: 'text' },
  { label: 'RFID Card', key: 'rfidCards', type: 'text' },
];
// headers for vehicle/telematics boxes polls
export const telematicsBoxPollHeaders = [
  { label: 'VIN', key: 'identificationNumber', type: 'text' },
  { label: 'IMEI', key: 'imei', type: 'text' },
  { label: 'Time', key: 'time', type: 'date' },
  { label: 'Longitude', key: 'longitude', type: 'number', precision: 6 },
  { label: 'Latitude', key: 'latitude', type: 'number', precision: 6 },
  { label: 'Heading (degrees)', key: 'headingDegrees', type: 'number' },
  { label: 'Speed (mph)', key: 'speedMilesPerHour', type: 'number' },
  { label: 'Speed Limit (mph)', key: 'speedLimitMilesPerHour', type: 'number' },
  {
    label: 'Malfunction Indicator Light',
    key: 'malfunctionIndicatorLightOn',
    type: 'boolean',
  },
  { label: 'Accelerometer Alert', key: 'accelerometerAlert', type: 'boolean' },
  { label: 'Odometer (miles)', key: 'odometerMiles', type: 'number' },
  { label: 'Ignition On', key: 'ignitionOn', type: 'boolean' },
  { label: 'Diagnostic Code', key: 'diagnosticCode', type: 'text' },
  ...Object.entries(dioStates)
    .filter(([k]) => !k.startsWith('unused'))
    .map(([key, label]) => ({ key, label })),
];

export const vehiclePollHeaders = [
  ...shortPersonHeaders,
  ...shortVehicleHeaders,
  ...telematicsBoxPollHeaders,
];

// headers for person/radio polls
export const radioPollHeaders = [
  { label: 'Radio SSI', key: 'radioSsi', type: 'number' },
  { label: 'Time', key: 'time', type: 'date' },
  { label: 'Longitude', key: 'longitude', type: 'number', precision: 6 },
  { label: 'Latitude', key: 'latitude', type: 'number', precision: 6 },
];

export async function getVehiclePolls(imei, startTime, endTime) {
  const response = await api
    .get('telematicsBoxPolls', {
      searchParams: encodeParams({
        query: {
          imei,
          time: { $gte: startTime, $lte: endTime },
        },
        projection: {
          identifier: true,
          imei: true,
          driver: true,
          position: true,
          time: true,
          headingDegrees: true,
          speedKilometresPerHour: true,
          malfunctionIndicatorLightsOn: true,
          accelerometerAlert: true,
          distanceKilometres: true,
          ignitionOn: true,
          diagnosticCode: true,
          reverseGeocode: true,
          ...Object.keys(dioStates).reduce((acc, key) => {
            acc[key] = true;
            return acc;
          }, {}),
        },
      }),
    })
    .json();

  const polls = (response || []).map(
    ({
      position,
      speedKilometresPerHour,
      distanceKilometres,
      reverseGeocode,
      ...poll
    }) => ({
      longitude: position ? position.coordinates[0] : 0,
      latitude: position ? position.coordinates[1] : 0,
      speedMilesPerHour: round(speedKilometresPerHour * 0.62137119, 2),
      odometerMiles: round(distanceKilometres * 0.62137119, 2),
      speedLimitMilesPerHour:
        reverseGeocode && !reverseGeocode.unknownLimit
          ? round(reverseGeocode.speedLimitKilometresPerHour * 0.62137119, 2)
          : undefined,
      position,
      ...poll,
    }),
  );

  return polls;
}

export async function getPersonPolls(radioSsi, startTime, endTime) {
  const response = await api
    .get('radioPolls', {
      searchParams: encodeParams({
        query: {
          ssi: radioSsi,
          time: { $gte: startTime, $lte: endTime },
        },
        projection: {
          ssi: true,
          time: true,
          headingDegrees: true,
          speedKilometresPerHour: true,
          deviceProperties: true,
          identifier: true,
          locations: true,
          gpsFix: true,
          position: true,
          features: true,
          objectives: true,
        },
      }),
    })
    .json();

  return response;
}

export const shortHumanizer = humanizeDuration.humanizer({
  language: 'shortEn',
  languages: {
    shortEn: {
      y: () => 'y',
      mo: () => 'mo',
      w: () => 'w',
      d: () => 'd',
      h: () => 'h',
      m: () => 'm',
      s: () => 's',
      ms: () => 'ms',
    },
  },
  spacer: '',
  round: true,
});

function getSourceFromDataType(dataType) {
  switch (dataType) {
    case 'Vehicle':
    case 'Vehicle Trips':
    case 'Vehicle Location Visits':
      return 'vehicles';
    case 'Person':
    case 'Person Trails':
    case 'Person Location Visits':
    case 'Driver Trips':
      return 'people';
    case 'Location':
    case 'Person Visits':
    case 'Vehicle Visits':
      return 'locations';
    case 'Objective':
    case 'Objective Attendances':
      return 'objectives';
    case 'Retrospective':
      return 'retrospectives';
    case 'Incident':
      return 'incidents';
    default:
      return null;
  }
}

function getParamsFromSource(source) {
  switch (source) {
    case 'vehicles':
      return {
        projection: {
          identificationNumber: true,
          fleetNumber: true,
          registrationNumber: true,
        },
      };
    case 'people':
      return {
        projection: {
          code: true,
          forenames: true,
          surname: true,
          collarNumber: true,
        },
      };
    case 'locations':
      return { projection: { code: true, type: true, name: true } };
    case 'objectives':
    case 'retrospectives':
      return { projection: { title: true } };
    case 'incidents':
      return { projection: { type: true, number: true } };
    default:
      return null;
  }
}

export async function getAuditItem(dataType, id) {
  const source = getSourceFromDataType(dataType);
  if (!source) {
    return null;
  }

  const searchParams = encodeParams(getParamsFromSource(source));
  const response = await api
    .get(`${source}/${id}`, {
      searchParams,
    })
    .json();

  return response;
}

export async function getPerson(id) {
  const response = await api
    .get(`people/${id}`, {
      searchParams: encodeParams({
        projection: {
          code: true,
          collarNumber: true,
          forenames: true,
          surname: true,
          category: true,
          radioSsi: true,
          role: true,
          groups: true,
          wards: true,
          rank: true,
        },
      }),
    })
    .json();

  return response;
}

export function reduceByType(arr) {
  if (Array.isArray(arr)) {
    return (arr || []).reduce((byType, item) => {
      if (item.name) {
        byType[item.type] = item.name;
      }
      return byType;
    }, {});
  } else {
    return {};
  }
}

export function groupsFilter(record, filter) {
  let groupMatch = true;

  const recordGroups = Object.keys(record.groups || {})
    .map((key) => record.groups[key])
    .flatMap((value) => value);

  Object.entries(filter.groups).forEach((keyValuePair) => {
    const match = keyValuePair[1].filter((element) =>
      recordGroups.includes(element),
    );

    if (keyValuePair[1].length !== 0 && match.length === 0) {
      groupMatch = false;
    }
  });

  return groupMatch;
}

export async function imeiValid(imei, id) {
  if (!imei) {
    return true;
  }

  const response = await api
    .get('vehicles', {
      searchParams: encodeParams({
        query: {
          telematicsBoxImei: imei,
        },
      }),
    })
    .json();

  if (response.length === 0) {
    return true;
  }

  return response.map((vehicle) => vehicle.identificationNumber).includes(id);
}

export async function ssiValid(ssi, id) {
  if (!ssi) {
    return true;
  }

  const response = await api
    .get('people', {
      searchParams: encodeParams({
        query: {
          radioSsi: ssi,
        },
        projection: { code: true },
      }),
    })
    .json();

  if (response.length === 0) {
    return true;
  }

  return response.map((person) => person.code).includes(id);
}

export function parseFilter(filters) {
  const units = {
    s: 1,
    m: 60,
    h: 3600,
    d: 86400,
  };

  if (filters === undefined) {
    return [];
  }

  const parsed = Object.entries(filters)
    .map((entry) =>
      entry[1]
        .filter((entry) => entry.value)
        .map(({ field, condition, value, unit }) => ({
          [entry[0] === 'event' ? field : `${entry[0]}.${field}`]: {
            [condition || '$eq']: isNaN(value)
              ? value
              : parseFloat(value) * (unit ? units[unit] : 1),
          },
        })),
    )
    .reduce((a, b) => [...a, ...b], []);

  return parsed;
}

export function parseSort(sort = []) {
  const orderBy = {
    fields: sort
      .filter((item) => !!item.field)
      .map((item) =>
        item.subject === 'event'
          ? `properties.${item.field}`
          : `properties.${item.subject}.${item.field}`,
      ),
    directions: sort.map((item) => item.direction),
  };

  return orderBy;
}

export async function getRfidErrors(id, rfids) {
  if (!rfids || !rfids.length) {
    return false;
  }

  const counts = rfids.reduce(
    (accumulator, { reference }) => ({
      ...accumulator,
      [reference]: (accumulator[reference] || 0) + 1,
    }),
    {},
  );

  const duplicates = Object.keys(counts).filter((a) => counts[a] > 1);

  if (duplicates.length) {
    return `${duplicates.join(', ')} duplicated`;
  }

  const used = (
    await Promise.all(
      rfids
        .filter((rfid) => rfid.reference)
        .map(async (rfid) => {
          const response = await api
            .get('people', {
              searchParams: encodeParams({
                query: {
                  'rfidCards.reference': rfid.reference,
                },
                projection: { code: true },
              }),
            })
            .json();

          return {
            reference: rfid.reference,
            inUse: response.filter((person) => person.code !== id).length > 0,
          };
        }),
    )
  )
    .filter((card) => card.inUse)
    .map((card) => card.reference);

  if (used.length) {
    return `${used.join(', ')} in use`;
  }

  return false;
}

export function checkLuhn(value) {
  return !(
    value
      .replace(/\D/g, '')
      .split('')
      .reverse()
      .reduce(function (a, d, i) {
        return (a + d * (i % 2 ? 2.2 : 1)) | 0;
      }, 0) % 10
  );
}

export function checkVin(value) {
  if (value.length !== 17) {
    return false;
  }

  return true;
}

export function formatDataForCsv(object, dateFormat) {
  let result = { ...object };
  for (const key in result) {
    if (result[key] instanceof Date) {
      result[key] = format(result[key], dateFormat);
    } else if (Array.isArray(result[key])) {
      result[key] = result[key].join(', ');
    } else if (
      /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/.test(result[key])
    ) {
      result[key] = format(new Date(result[key]), dateFormat);
    } else if (result[key] instanceof Object) {
      result[key] = formatDataForCsv(result[key], dateFormat);
    }
  }
  return result;
}

// if any of the headers refer to nested objects, flatten these so
// { vehicle: { registration: 'abc' }}
//
// { "vehicle.registration": "abc" ...}
function flattenForHeaders(item, headers) {
  headers.forEach((header) => {
    let value = get(item, header.key);

    if (header.type === 'number') {
      value = round(value ?? 0, header.precision ?? 2);
    }

    item[header.key] = value;
  });

  return item;
}

export function downloadCSV(data, filename, headers, extraInfo = {}) {
  log('Read', 'CSV', { filename, ...extraInfo });

  const header = headers
    ? Papa.unparse(
        { fields: headers.map((h) => h.label), data: [] },
        { header: true },
      )
    : '';

  const body = headers
    ? Papa.unparse(
        data
          .map((item) => formatDataForCsv(item, 'dd/MM/yyyy HH:mm:ss'))
          .map((item) => flattenForHeaders(item, headers)),
        {
          header: false,
          columns: headers.map((h) => h.key),
        },
      )
    : Papa.unparse(
        data.map((item) => formatDataForCsv(item, 'dd/MM/yyyy HH:mm:ss')),
      );
  const csv = header + body;
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, filename);
  } else {
    const url = URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = url;
    a.setAttribute('download', filename);
    a.click();
  }
}

export function downloadJSON(data, filename) {
  log('Read', 'JSON', { filename });

  const blob = new Blob([JSON.stringify(data, null, 2)], {
    type: 'application/json',
  });
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, filename);
  } else {
    const url = URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = url;
    a.setAttribute('download', filename);
    a.click();
  }
}

export function downloadGeoJSON(data, filename) {
  log('Read', 'GeoJSON', { filename });

  const geo = JSON.stringify(convertToGeoJSON(data));
  const blob = new Blob([geo], {
    type: 'application/json',
  });
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, filename);
  } else {
    const url = URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = url;
    a.setAttribute('download', filename);
    a.click();
  }
}

function convertToGeoJSON(data) {
  const features = data.map((feature) => {
    const { boundary, path, point, position, ...props } = feature;
    const geometry = boundary || path || point || position || {};

    return {
      type: 'Feature',
      geometry,
      properties: props,
    };
  });

  let geojson = {
    type: 'FeatureCollection',
    features: features,
  };

  rewind(geojson, false);

  return geojson;
}

export async function getVehicles() {
  const response = await api
    .get('vehicles', {
      searchParams: encodeParams({
        projection: {
          identificationNumber: true,
          registrationNumber: true,
          fleetNumber: true,
          role: true,
          type: true,
          make: true,
          model: true,
          colour: true,
          marked: true,
          homeStation: true,
          groups: true,
          equipment: true,
          telematicsBoxImei: true,
          lastPollTime: true,
          lastOdometerReading: true,
          fuelType: true,
          disposalDate: true,
          visibleTo: true,
          driverIdLocation: true,
          installLocation: true,
          commissionDate: true,
          notes: true,
        },
      }),
    })
    .json();

  return response;
}

export async function getPeople() {
  const response = await api
    .get('people', {
      searchParams: encodeParams({
        projection: {
          code: true,
          forenames: true,
          surname: true,
          collarNumber: true,
          emailAddress: true,
          mobileNumber: true,
          supervisorCode: true,
          postNumber: true,
          rank: true,
          role: true,
          homeStation: true,
          wards: true,
          groups: true,
          skills: true,
          radioSsi: true,
          lastPollTime: true,
          visibleTo: true,
          rfidCards: true,
        },
      }),
    })
    .json();

  return response;
}

export async function getLocations(type) {
  const response = await api
    .get('locations', {
      searchParams: encodeParams({
        projection: {
          code: true,
          name: true,
          type: true,
          groups: true,
          subtype: true,
          boundary: true,
          startTime: true,
          endTime: true,
        },
        index: { name: 'type', values: [type] },
      }),
    })
    .json();

  return response;
}

const commissionHeaders = [
  { label: 'Commission Date', key: 'commissionDate', type: 'date' },
  { label: 'Unit Location', key: 'installLocation', type: 'text' },
  {
    label: `${useDallasKeys ? 'Dallas Key' : 'RFID Card'} Reader Location`,
    key: 'driverIdLocation',
    type: 'text',
  },
  { label: 'Notes', key: 'notes', type: 'text' },
];

const vehicleHeaders = [
  { label: 'VIN', key: 'identificationNumber', type: 'text' },
  { label: 'Registration', key: 'registrationNumber', type: 'text' },
  { label: 'Fleet Number', key: 'fleetNumber', type: 'text' },
  { label: 'Role', key: 'role', type: 'text' },
  { label: 'Type', key: 'type', type: 'text' },
  { label: 'Make', key: 'make', type: 'text' },
  { label: 'Model', key: 'model', type: 'text' },
  { label: 'Colour', key: 'colour', type: 'text' },
  { label: 'Marked', key: 'marked', type: 'text' },
  { label: 'Home Station', key: 'homeStation', type: 'text' },
  { label: 'Equipment', key: 'equipment', type: 'text' },
  { label: 'IMEI', key: 'telematicsBoxImei', type: 'text' },
  { label: 'Last Poll Time', key: 'lastPollTime', type: 'date' },
  {
    label: 'Last Odometer Reading Date & Time',
    key: 'lastOdometerReadingTime',
    type: 'date',
  },
  {
    label: 'Last Odometer Reading Miles',
    key: 'lastOdometerReadingMiles',
    type: 'date',
  },
  { label: 'Fuel Type', key: 'fuelType', type: 'text' },
  { label: 'Disposed Date & Time', key: 'disposalDate', type: 'date' },
  ...(useRestricted
    ? [{ label: 'Visible To', key: 'visibleto', type: 'text' }]
    : []),
];

export async function getVehiclesAndHeaders() {
  const response = await api
    .get('vehicles', {
      searchParams: encodeParams({
        projection: {
          identificationNumber: true,
          registrationNumber: true,
          fleetNumber: true,
          role: true,
          type: true,
          make: true,
          model: true,
          colour: true,
          marked: true,
          homeStation: true,
          groups: true,
          equipment: true,
          telematicsBoxImei: true,
          lastPollTime: true,
          lastOdometerReading: true,
          fuelType: true,
          disposalDate: true,
          driverIdLocation: true,
          installLocation: true,
          commissionDate: true,
          notes: true,
        },
      }),
      headers: getHeaders(),
    })
    .json();

  const groupTypes = Array.from(
    new Set(
      [].concat(...response.map((record) => Object.keys(record.groups || {}))),
    ),
  );

  const vehicles = response.map(
    ({
      groups,
      lastPollTime,
      lastOdometerReading,
      disposalDate,
      equipment,
      ...vehicle
    }) => ({
      ...vehicle,
      equipment: (equipment || [])
        .map((equipment) => equipment.name)
        .toString(),
      lastPollTime: lastPollTime
        ? format(new Date(lastPollTime), 'dd/MM/yyyy HH:mm:ss')
        : '',
      disposalDate: disposalDate
        ? format(new Date(disposalDate), 'dd/MM/yyyy HH:mm:ss')
        : '',
      lastOdometerReadingTime:
        lastOdometerReading && lastOdometerReading.time
          ? format(new Date(lastOdometerReading.time), 'dd/MM/yyyy HH:mm:ss')
          : '',
      lastOdometerReadingMiles:
        lastOdometerReading && lastOdometerReading.distanceKilometres
          ? round(lastOdometerReading.distanceKilometres * 0.62137119, 2)
          : '',
      ...groups,
      commissionDate: vehicle.commissionDate
        ? format(new Date(vehicle.commissionDate), 'dd/MM/yyyy HH:mm:ss')
        : '',
    }),
  );

  return {
    vehicles,
    headers: [
      ...vehicleHeaders,
      ...groupTypes.map((type) => ({
        label: startCase(type),
        key: type,
        type: 'text',
      })),
      ...commissionHeaders,
    ],
  };
}

const peopleHeaders = [
  { label: 'Staff ID', key: 'code', type: 'text' },
  { label: 'Forenames', key: 'forenames', type: 'text' },
  { label: 'Surname', key: 'surname', type: 'text' },
  { label: 'Collar Number', key: 'collarNumber', type: 'text' },
  { label: 'Email Address', key: 'emailAddress', type: 'text' },
  { label: 'Mobile Number', key: 'mobileNumber', type: 'text' },
  { label: 'Supervisor', key: 'supervisorCode', type: 'text' },
  { label: 'Rank', key: 'rank', type: 'text' },
  { label: 'Role', key: 'role', type: 'text' },
  { label: 'Home Station', key: 'homeStation', type: 'text' },
  { label: 'Wards', key: 'wards', type: 'text' },
  { label: 'Skills', key: 'skills', type: 'text' },
  { label: 'Radio SSI', key: 'radioSsi', type: 'number' },
  { label: 'Last Poll Time', key: 'lastPollTime', type: 'date' },
  {
    label: useDallasKeys ? 'Dallas Key' : 'RFID Card',
    key: 'rfidCards',
    type: 'text',
  },
  { label: 'Leaving Date & Time', key: 'leavingDate', type: 'date' },
  ...(useRestricted
    ? [{ label: 'Visible To', key: 'visibleTo', type: 'text' }]
    : []),
];

export async function getPeopleAndHeaders() {
  const response = await api
    .get('people', {
      searchParams: encodeParams({
        projection: {
          code: true,
          forenames: true,
          surname: true,
          collarNumber: true,
          emailAddress: true,
          mobileNumber: true,
          supervisorCode: true,
          rank: true,
          role: true,
          homeStation: true,
          wards: true,
          groups: true,
          skills: true,
          radioSsi: true,
          lastPollTime: true,
          visibleTo: true,
          rfidCards: true,
          leavingDate: true,
        },
      }),
    })
    .json();

  const groupTypes = Array.from(
    new Set(
      [].concat(...response.map((record) => Object.keys(record.groups || {}))),
    ),
  );

  const people = response.map(
    ({
      groups,
      lastPollTime,
      leavingDate,
      wards,
      skills,
      rfidCards,
      rank,
      ...person
    }) => ({
      ...person,
      wards: (wards || []).toString(),
      skills: (skills || []).map((skill) => skill.name).toString(),
      lastPollTime: lastPollTime
        ? format(new Date(lastPollTime), 'dd/MM/yyyy HH:mm:ss')
        : '',
      leavingDate: leavingDate
        ? format(new Date(leavingDate), 'dd/MM/yyyy HH:mm:ss')
        : '',
      rfidCards: (rfidCards || []).map((card) => card.reference).toString(),
      rank: rank ? rank.code : '',
      ...groups,
    }),
  );

  return {
    people,
    headers: [
      ...peopleHeaders,
      ...groupTypes.map((type) => ({
        label: startCase(type),
        key: type,
        type: 'text',
      })),
    ],
  };
}

export async function fetchPersonRequest(id) {
  const response = await api
    .post('pipeline/people', {
      json: [
        { $match: { code: id } },
        {
          $graphLookup: {
            from: 'groups',
            startWith: '$groupCodes',
            connectFromField: 'parentCodes',
            connectToField: 'code',
            as: 'groupAncestors',
            depthField: 'depth',
          },
        },
        {
          $project: {
            code: true,
            picture: true,
            forenames: true,
            surname: true,
            collarNumber: true,
            emailAddress: true,
            mobileNumber: true,
            supervisorCode: true,
            postNumber: true,
            rank: true,
            role: true,
            homeStation: true,
            wards: true,
            skills: true,
            radioSsi: true,
            lastPollTime: true,
            rfidCards: true,
            ward: true,
            supervisorCodes: true,
            leavingDate: true,
            groups: true,
            groupAncestors: {
              $map: {
                input: {
                  $sortArray: {
                    input: '$groupAncestors',
                    sortBy: { depth: -1, type: 1, name: 1 },
                  },
                },
                as: 'group',
                in: {
                  name: '$$group.name',
                  type: '$$group.type',
                },
              },
            },
          },
        },
      ],
    })
    .json();

  log('Read', 'People', { id });

  if (response.length > 0) {
    return response[0];
  } else {
    throw new Error('Not found');
  }
}

export async function fetchVehicleRequest(id) {
  const response = await api
    .post('pipeline/vehicles', {
      json: [
        { $match: { identificationNumber: id } },
        {
          $graphLookup: {
            from: 'groups',
            startWith: '$groupCodes',
            connectFromField: 'parentCodes',
            connectToField: 'code',
            as: 'groupAncestors',
            depthField: 'depth',
          },
        },
        {
          $project: {
            identificationNumber: true,
            registrationNumber: true,
            fleetNumber: true,
            picture: true,
            role: true,
            type: true,
            make: true,
            model: true,
            colour: true,
            marked: true,
            homeStation: true,
            equipment: true,
            telematicsBoxImei: true,
            lastPollTime: true,
            lastOdometerReading: {
              time: true,
              distanceMiles: {
                $round: [
                  {
                    $multiply: [
                      '$lastOdometerReading.distanceKilometres',
                      0.62137119,
                    ],
                  },
                  2,
                ],
              },
            },
            fuelType: true,
            disposalDate: true,
            groups: true,
            visibleTo: true,
            groupAncestors: {
              $map: {
                input: {
                  $sortArray: {
                    input: '$groupAncestors',
                    sortBy: { depth: -1, type: 1, name: 1 },
                  },
                },
                as: 'group',
                in: {
                  name: '$$group.name',
                  type: '$$group.type',
                },
              },
            },
          },
        },
      ],
    })
    .json();

  log('Read', 'Vehicles', { id });

  if (response.length > 0) {
    return response[0];
  } else {
    throw new Error('Not found');
  }
}

export async function fetchLocationRequest(id) {
  const response = await api
    .post('pipeline/locations', {
      json: [
        { $match: { code: id } },
        {
          $graphLookup: {
            from: 'groups',
            startWith: '$groupCodes',
            connectFromField: 'parentCodes',
            connectToField: 'code',
            as: 'groupAncestors',
            depthField: 'depth',
          },
        },
        {
          $project: {
            code: true,
            name: true,
            type: true,
            groups: true,
            subtype: true,
            boundary: true,
            startTime: true,
            endTime: true,
            groupAncestors: {
              $map: {
                input: {
                  $sortArray: {
                    input: '$groupAncestors',
                    sortBy: { depth: -1, type: 1, name: 1 },
                  },
                },
                as: 'group',
                in: {
                  name: '$$group.name',
                  type: '$$group.type',
                },
              },
            },
          },
        },
      ],
    })
    .json();

  log('Read', 'Locations', { id });

  if (response.length > 0) {
    return response[0];
  } else {
    throw new Error('Not found');
  }
}

const redirectUri = `${window.location.origin}`;

export function getRefreshedAccessToken() {
  const refreshToken = localStorage.getItem('refresh_token');
  return ky
    .post(`${apiRootUrl}/${authenticationScheme}/token`, {
      body: new URLSearchParams({
        grant_type: 'refresh_token',
        client_id: clientId,
        refresh_token: refreshToken,
        redirect_uri: redirectUri,
        resource,
      }),
    })
    .json();
}

const getStaleThresholds = _.throttle(() => {
  function calc(threshold) {
    return threshold && subMinutes(new Date(), threshold).toISOString();
  }

  return {
    vehicleIgnitionOn: calc(staleVehicleIgnitionOnThreshold),
    vehicleIgnitionOff: calc(staleVehicleIgnitionOffThreshold),
    person: calc(stalePersonThreshold),
  };
}, 60 * 1000);

function getLastPollTime(resource, type) {
  let lastPollTime = resource.lastPollTime || '0';

  if (type === 'radios') {
    lastPollTime = resource.lastRadioPoll.time;
  }

  if (type === 'telematicsBoxes') {
    lastPollTime = resource.mostRecentPoll.time;
  }

  return lastPollTime;
}

export function isStale(resource, type) {
  const lastPollTime = getLastPollTime(resource, type);
  const thresholds = getStaleThresholds();

  function olderThan(threshold) {
    // the latest poll is older than the threshold if there is a threshold
    // and the poll is less than (i.e. older than) the threshold
    return threshold && lastPollTime < threshold;
  }

  if (type === 'vehicles' || type === 'telematicsBoxes') {
    return resource.ignitionOn
      ? olderThan(thresholds.vehicleIgnitionOn)
      : olderThan(thresholds.vehicleIgnitionOff);
  } else if (type === 'people' || type === 'radios') {
    return olderThan(thresholds.person);
  }

  return false;
}

export function getRfidCardHeaders(eventType) {
  const keyField = eventType === 'stops' ? 'lastRfidCard' : 'rfidCard';
  return [
    {
      label: useDallasKeys ? 'Dallas Key' : 'RFID Card',
      key: `${keyField}.reference`,
      type: 'text',
      filter: true,
    },
    {
      label: `${useDallasKeys ? 'Dallas Key' : 'RFID Card'} Type`,
      key: `${keyField}.type`,
      type: 'text',
      filter: true,
    },
    {
      label: `${useDallasKeys ? 'Dallas Key' : 'RFID Card'} Label`,
      key: `${keyField}.label`,
      type: 'text',
      filter: true,
    },
  ];
}
export function formatGroups(groups) {
  let groupsValues = {};
  Object.keys(groups || []).forEach(
    (key) => (groupsValues[key] = groups[key].join(',')),
  );
  return groupsValues;
}

export function getFilenameForDownload(
  label = 'download',
  extension = 'csv',
  startTime,
  endTime,
) {
  let filename = label;
  const dateFormat = 'yyyy-MM-dd_HHmm';
  const isValidDateRange = !isNaN(startTime) && !isNaN(endTime);

  if (isValidDateRange) {
    const dateRange = `${format(startTime, dateFormat)}-${format(
      endTime,
      dateFormat,
    )}`;
    filename = `${filename}-${dateRange}`;
  }

  if (!startTime && !endTime) {
    filename = `${filename}-${format(new Date(), dateFormat)}`;
  }

  return `${filename}.${extension}`;
}

export function containsKeyIgnoreCase(object, key) {
  return !!Object.keys(object).find(
    (k) => k.toLowerCase() === key.toLowerCase(),
  );
}

export function notify(title, options, onClick) {
  if (!('Notification' in window)) {
    alert('This browser does not support desktop notification');
  } else if (Notification.permission === 'granted') {
    const notification = new Notification(title, options);
    notification.onclick = onClick;
  } else if (Notification.permission !== 'denied') {
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        const notification = new Notification(title, options);
        notification.onclick = onClick;
      }
    });
  }
}

export const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export function applySearch(item, searchTexts) {
  if (!searchTexts || searchTexts === '' || !item) {
    return true;
  }

  return _.includes(item.searchString, searchTexts);
}

// export function applyExtendedFilters(item, filters) {
//   if (!item) return true;

//   return filters
//     ? Object.entries(filters).every(([key, options]) =>
//         // for single select: val !== '' ? item[key] === val : true
//         options.length !== 0
//           ? options.some(option => item[key] === option.value)
//           : true
//       )
//     : [];
// }

export async function populateDescendants() {
  const json = [
    {
      $graphLookup: {
        from: 'groups',
        startWith: '$code',
        connectFromField: 'code',
        connectToField: 'parentCodes',
        as: 'decendentCodes',
      },
    },
    {
      $project: {
        _id: false,
        code: true,
        decendentCodes: {
          $map: {
            input: '$decendentCodes',
            as: 'group',
            in: '$$group.code',
          },
        },
      },
    },
  ];

  const groups = await api.post('pipeline/groups', { json }).json();

  window.groupDescendants = groups.reduce((acc, group) => {
    acc[group.code] = [group.code, ...group.decendentCodes];

    return acc;
  }, {});
}

function isDescendantOf(groupCodes = [], selectedGroupCodes = []) {
  return groupCodes.some((groupCode) =>
    selectedGroupCodes.some((selectedGroupCode) =>
      (
        (window.groupDescendants ?? {})[selectedGroupCode] ?? [
          selectedGroupCode,
        ]
      ).includes(groupCode),
    ),
  );
}

export function applyAdvancedFilters(item, filters) {
  if (!item || !filters) {
    return true;
  }

  return filters.every((filter) => {
    if (filter.field === 'groupCodes') {
      return isDescendantOf(item.groupCodes, filter.value);
    }

    const itemValue = get(item, filter.field);
    const filterValueIsArray = Array.isArray(filter.value);
    const specialValue = (value) =>
      filterValueIsArray
        ? filter.value.some((f) => f === value)
        : filter.value === value;
    const matchAnyValue = specialValue('*Any');
    const matchNoValue = specialValue('*None');

    const unfinishedFilterDefinition =
      filter.field == null ||
      filter.field === '' ||
      filter.value == null ||
      filter.value === '' ||
      (filterValueIsArray && filter.value.length === 0);

    // if the filter isn't complete don't exclude (i.e. return true)
    if (unfinishedFilterDefinition) {
      return true;
    }

    // if the item's value is an array (e.g. skills)
    // test that any of item's values match
    if (Array.isArray(itemValue)) {
      if (itemValue.length > 0 ? matchAnyValue : matchNoValue) {
        return true;
      }

      const itemArrayMatch = (option) =>
        itemValue.some((element) => match(element, filter.condition, option));

      // if the filter's value is also an array...
      if (filterValueIsArray) {
        return filter.value.some((filterOption) =>
          itemArrayMatch(filterOption),
        );
      }

      return itemArrayMatch(filter.value);
    }

    // the item has a value and the "*Any" option is selected it's a match
    // similarly if the item has no value and "*None" is selected
    const hasValue = itemValue !== undefined && itemValue !== '';
    if (hasValue ? matchAnyValue : matchNoValue) {
      return true;
    }

    // if the filter is an array test the item's value
    // against any of the filter's values
    if (filterValueIsArray) {
      return filter.value.some((filterOption) =>
        match(itemValue, filter.condition, filterOption),
      );
    }

    // otherwise match filter value against item value
    return match(itemValue, filter.condition, filter.value);
  });
}

// handle filtering in the epic, whenever the filters/search texts change,
// adjust the filteredInIdsByType
// helper for filtering a dictionary
export function objFilter(obj, predicate, arg) {
  return Object.keys(obj)
    .filter((key) => predicate(obj[key], arg))
    .reduce((result, key) => {
      result[key] = obj[key];
      return result;
    }, {});
}

// filters an item dictionary based on searchTexts (e.g. "abc") and filters
// (e.g. vehicle.registrations = ["REG1234"])
export function filterDict(dict, searchText, filters, advancedFilters) {
  dict = dict && objFilter(dict, applySearch, searchText?.toLowerCase());
  // dict = dict && objFilter(dict, applyExtendedFilters, filters);
  dict = dict && objFilter(dict, applyAdvancedFilters, advancedFilters);

  return dict;
}

export function filterList(list, searchText, filters, advancedFilters) {
  list =
    list && list.filter((item) => applySearch(item, searchText?.toLowerCase()));
  // list = list && list.filter(item => applyExtendedFilters(item, filters));
  list =
    list && list.filter((item) => applyAdvancedFilters(item, advancedFilters));

  return list;
}

//general filtering function to filter fetched data locally
export function filterLocally(filter, data) {
  return data.filter((dataItem) => {
    return Object.entries(filter.fields).every(([key, value]) => {
      return (
        value === null ||
        value === get(dataItem, key) ||
        (Array.isArray(get(dataItem, key)) &&
          get(dataItem, key).includes(value))
      );
    });
  });
}

export const idProperties = {
  vehicles: 'identificationNumber',
  people: 'code',
  incidents: 'number',
  accelerometerAlerts: 'identifier',
  accelerometerEvents: 'identifier',
  objectives: 'identifier',
  locations: 'code',
  radios: 'ssi',
  telematicsBoxes: 'imei',
};

export class NormalDistribution {
  constructor(mean, sd) {
    if (!mean) {
      mean = 0.0;
    }
    if (!sd) {
      sd = 1.0;
    }
    this.mean = mean;
    this.sd = sd;
    // eslint-disable-next-line no-loss-of-precision
    this.Sqrt2 = 1.4142135623730950488016887;
    // eslint-disable-next-line no-loss-of-precision
    this.Sqrt2PI = 2.50662827463100050242;
    this.lnconstant = -Math.log(this.Sqrt2PI * sd);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  sample() {}

  cumulativeProbability(x) {
    var z = (x - this.mean) / (this.Sqrt2 * this.sd);
    return 0.5 + 0.5 * this.errorFunc(z);
  }

  invCumulativeProbability(p) {
    var Z = this.Sqrt2 * this.invErrorFunc(2 * p - 1);
    return Z * this.sd + this.mean;
  }

  errorFunc(z) {
    var t = 1.0 / (1.0 + 0.5 * Math.abs(z));

    // use Horner's method
    var ans =
      1 -
      t *
        Math.exp(
          -z * z -
            1.26551223 +
            t *
              (1.00002368 +
                t *
                  (0.37409196 +
                    t *
                      (0.09678418 +
                        t *
                          (-0.18628806 +
                            t *
                              (0.27886807 +
                                t *
                                  (-1.13520398 +
                                    t *
                                      (1.48851587 +
                                        t *
                                          (-0.82215223 + t * 0.17087277)))))))),
        );
    return z >= 0 ? ans : -ans;
  }

  invErrorFunc(x) {
    var z;
    var a = 0.147;
    var the_sign_of_x;
    if (0 === x) {
      the_sign_of_x = 0;
    } else if (x > 0) {
      the_sign_of_x = 1;
    } else {
      the_sign_of_x = -1;
    }

    if (0 !== x) {
      var ln_1minus_x_sqrd = Math.log(1 - x * x);
      var ln_1minusxx_by_a = ln_1minus_x_sqrd / a;
      var ln_1minusxx_by_2 = ln_1minus_x_sqrd / 2;
      var ln_etc_by2_plus2 = ln_1minusxx_by_2 + 2 / (Math.PI * a);
      var first_sqrt = Math.sqrt(
        ln_etc_by2_plus2 * ln_etc_by2_plus2 - ln_1minusxx_by_a,
      );
      var second_sqrt = Math.sqrt(first_sqrt - ln_etc_by2_plus2);
      z = second_sqrt * the_sign_of_x;
    } else {
      // x is zero
      z = 0;
    }
    return z;
  }
}

export function startCase(camelCase) {
  if (!camelCase) {
    return camelCase;
  }

  return camelCase
    .toString()
    .replace(/[0-9]{2,}/g, (match) => ` ${match} `)
    .replace(/[^A-Z0-9][A-Z]/g, (match) => `${match[0]} ${match[1]}`)
    .replace(
      /[A-Z][A-Z][^A-Z0-9]/g,
      (match) => `${match[0]} ${match[1]}${match[2]}`,
    )
    .replace(/[ ]{2,}/g, () => ' ')
    .replace(/\s./g, (match) => match.toUpperCase())
    .replace(/^./, (match) => match.toUpperCase())
    .trim();
}

// export function startCase(text) {
//   if (!text) {
//     return text;
//   }

//   return text
//     .toString()
//     .replace(/-/g, ' ')
//     .toLowerCase()
//     .split(' ')
//     .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
//     .join(' ');
// }

export function getFilterOptionsFromData(data, filter, filterFunction) {
  const { groups: _, ...fields } = filter;
  let groupOptions = {};
  data.forEach((record) => {
    const groups = record.groups ?? {};
    Object.keys(groups).forEach((key) => {
      if (!groupOptions[key]) {
        groupOptions[key] = [...groups[key]];
      } else {
        groups[key].forEach((type) => {
          if (!groupOptions[key].includes(type)) {
            groupOptions[key].push(type);
          }
        });
      }
    });
  });

  let result = { groups: groupOptions };

  for (const key in fields) {
    const keyFilter = { ...filter, [key]: [] };
    let valueDictionary = {};
    data
      .filter((record) => filterFunction(record, keyFilter))
      .map((record) => record[key])
      .filter((value) => value !== undefined)
      .forEach((value) => {
        if (!valueDictionary[value]) {
          valueDictionary[value] = true;
        }
      });

    result[key] = Object.keys(valueDictionary)
      .filter((v) => v !== undefined)
      .sort();
  }

  return result;
}

export function noop() {
  return undefined;
}

export function getMicrobeat(easting, northing) {
  const GRID_SIZE = 300;
  const EASTING_OFFSET = 131;
  const NORTHING_OFFSET = 138;

  const east =
    Math.floor((easting - EASTING_OFFSET) / GRID_SIZE) * GRID_SIZE +
    EASTING_OFFSET +
    GRID_SIZE / 2;
  const north =
    Math.floor((northing - NORTHING_OFFSET) / GRID_SIZE) * GRID_SIZE +
    NORTHING_OFFSET +
    GRID_SIZE / 2;

  return `${east.toString().padStart(6, '0')}${north
    .toString()
    .padStart(6, '0')}`;
}

const EPSG27700 =
  '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +ellps=airy +datum=OSGB36 +units=m +no_defs';

export function getMicrobeatFromText(text) {
  return getMicrobeat(
    Number.parseInt(text.substring(0, 6)),
    Number.parseInt(text.substring(6, 12)),
  );
}

export function getMicrobeatFromLngLat(lngLat) {
  const [easting, northing] = proj4(EPSG27700, [lngLat.lng, lngLat.lat]);

  return getMicrobeat(Math.round(easting), Math.round(northing));
}

export function getFeatureFromMicrobeat(microbeat) {
  const centre = [
    Number.parseInt(microbeat.substring(0, 6)),
    Number.parseInt(microbeat.substring(6, 12)),
  ];
  const boundary = [
    [centre[0] - 150, centre[1] - 150],
    [centre[0] - 150, centre[1] + 150],
    [centre[0] + 150, centre[1] + 150],
    [centre[0] + 150, centre[1] - 150],
    [centre[0] - 150, centre[1] - 150],
  ];
  const coordinates = boundary.map((en) => proj4(EPSG27700, 'EPSG:4326', en));

  return {
    type: 'Feature',
    properties: {
      id: microbeat,
    },
    geometry: {
      type: 'Polygon',
      coordinates: [coordinates],
    },
  };
}

export function getFeatureCollectionFromMicrobeats(microbeats) {
  return {
    type: 'FeatureCollection',
    features: microbeats.map((microbeat) => getFeatureFromMicrobeat(microbeat)),
  };
}

function getCentrePointsFromMicrobeats(microbeats) {
  return microbeats.map((microbeat) => {
    const centre = [
      Number.parseInt(microbeat.substring(0, 6)),
      Number.parseInt(microbeat.substring(6, 12)),
    ];
    const coordinates = proj4(EPSG27700, 'EPSG:4326', centre);

    return {
      type: 'Point',
      coordinates,
    };
  });
}

export function getGeometryCollectionFromMicrobeats(microbeats) {
  const points = getCentrePointsFromMicrobeats(microbeats);
  const boundaries = getFeatureCollectionFromMicrobeats(
    microbeats,
  ).features.map((feature) => feature.geometry);

  return {
    type: 'GeometryCollection',
    geometries: [...points, ...boundaries],
  };
}

export function encodeParams(params) {
  return Object.entries(params || {})
    .filter((kv) => kv[1] !== undefined)
    .map((kv) => `${kv[0]}=${encodeURIComponent(JSON.stringify(kv[1]))}`)
    .join('&');
}

export function getTextWidth(text, font) {
  // re-use canvas object for better performance
  const canvas =
    getTextWidth.canvas ||
    (getTextWidth.canvas = document.createElement('canvas'));
  const context = canvas.getContext('2d');
  context.font = font;
  const metrics = context.measureText(text);
  return metrics.width;
}

export function getKeyLabel(key) {
  const upperCaseNames = ['bcu', 'lpu'];
  const name = key.split('.').pop();

  return upperCaseNames.includes(name) ? _.upperCase(name) : startCase(name);
}

export function hexToRgba(hex, a) {
  const r = parseInt(hex.substring(1, 3), 16);
  const g = parseInt(hex.substring(3, 5), 16);
  const b = parseInt(hex.substring(5, 7), 16);

  return `rgba(${r},${g},${b},${a})`;
}

export function randomHsl(step, totalSteps) {
  return `hsl(${(step / totalSteps) * 360}, 100%, 42%)`;
}

export function indexArray(length) {
  return Array(length)
    .fill()
    .map((_, i) => i);
}

export function rearrange(arr, oldIndex, newIndex) {
  let tempArr = [...arr];

  const oldValue = tempArr[oldIndex];
  // if the newPosition is higher up, we need to move everything between down one
  if (newIndex > oldIndex) {
    for (let i = oldIndex; i < newIndex; i++) {
      tempArr[i] = tempArr[i + 1];
    }
    tempArr[newIndex] = oldValue;
  } else {
    // otherwise, move everything from newIndex to oldIndex up one
    for (let i = oldIndex; i > newIndex; i--) {
      tempArr[i] = tempArr[i - 1];
    }
    tempArr[newIndex] = oldValue;
  }

  return tempArr;
}

export const getColor =
  (value) =>
  ({ palette }) => {
    const color =
      value < 1
        ? palette.warning.main
        : value > 1
          ? palette.error.main
          : palette.success.main;

    return {
      bgcolor:
        palette.mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6),
      '&:hover': {
        bgcolor:
          palette.mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5),
      },
      '&:last-child td, &:last-child th': { border: 0 },
    };
  };

export function round(num, precision) {
  if (!precision) {
    return Math.round(num);
  } else {
    const modifier = 10 ** precision;
    return Math.round(num * modifier) / modifier;
  }
}

export function get(obj, path, defValue) {
  // If path is not defined or it has false value
  if (!path) return undefined;
  // Check if path is string or array. Regex : ensure that we do not have '.' and brackets.
  // Regex explained: https://regexr.com/58j0k
  const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g);
  // Find value
  const result = pathArray.reduce(
    (prevObj, key) => prevObj && prevObj[key],
    obj,
  );
  // If found value is undefined return default value; otherwise return the value
  return result === undefined ? defValue : result;
}

export function maxBy(arr, func) {
  const max = Math.max(...arr.map(func));
  return arr.find((item) => func(item) === max);
}

export const debounce = (func, delay, { leading } = {}) => {
  let timerId;

  return (...args) => {
    if (!timerId && leading) {
      func(...args);
    }
    clearTimeout(timerId);

    timerId = setTimeout(() => func(...args), delay);
  };
};

export function omit(obj, props) {
  obj = { ...obj };
  props.forEach((prop) => delete obj[prop]);
  return obj;
}

export function has(obj, path) {
  // Regex explained: https://regexr.com/58j0k
  const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g);

  return !!pathArray.reduce((prevObj, key) => prevObj && prevObj[key], obj);
}

export function max(nums) {
  if (nums.length) {
    return Math.max(...nums);
  }
}

export function range(start, end, increment) {
  // if the end is not defined...
  const isEndDef = typeof end !== 'undefined';
  // ...the first argument should be the end of the range...
  end = isEndDef ? end : start;
  // ...and 0 should be the start
  start = isEndDef ? start : 0;

  // if the increment is not defined, we could need a +1 or -1
  // depending on whether we are going up or down
  if (typeof increment === 'undefined') {
    increment = Math.sign(end - start);
  }

  // calculating the lenght of the array, which has always to be positive
  const length = Math.abs((end - start) / (increment || 1));

  // In order to return the right result, we need to create a new array
  // with the calculated length and fill it with the items starting from
  // the start value + the value of increment.
  const { result } = Array.from({ length }).reduce(
    ({ result, current }) => ({
      // append the current value to the result array
      result: [...result, current],
      // adding the increment to the current item
      // to be used in the next iteration
      current: current + increment,
    }),
    { current: start, result: [] },
  );

  return result;
}

export function uniqueLabelValues(collection, mapping) {
  const labelValue = (value) => ({ label: value, value });

  return _.uniq(collection.map(mapping).filter(Boolean)).sort().map(labelValue);
}

export function reviveDate(key, value) {
  const isoDateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;

  return typeof value === 'string' && isoDateRegex.test(value)
    ? new Date(value)
    : value;
}

export const sortByKey = (key) => (a, b) =>
  get(a, key) > get(b, key) ? 1 : get(b, key) > get(a, key) ? -1 : 0;

export function isEmpty(obj) {
  for (var i in obj) {
    return false;
  }
  return true;
}

export function getDurations(startTime, endTime) {
  const count = differenceInHours(
    addHours(startOfHour(new Date(endTime)), 1),
    startOfHour(new Date(startTime)),
  );

  if (count === 1) {
    return [
      {
        hour: startOfHour(new Date(startTime)),
        durationSeconds: differenceInSeconds(
          new Date(endTime),
          new Date(startTime),
        ),
      },
    ];
  } else if (count === 2) {
    return [
      {
        hour: startOfHour(new Date(startTime)),
        durationSeconds: differenceInSeconds(
          new Date(endTime),
          new Date(startTime),
        ),
      },
      {
        hour: startOfHour(new Date(endTime)),
        durationSeconds: differenceInSeconds(
          new Date(endTime),
          startOfHour(new Date(endTime)),
        ),
      },
    ];
  } else {
    return [
      {
        hour: startOfHour(new Date(startTime)),
        durationSeconds: differenceInSeconds(
          startOfHour(addHours(new Date(startTime), 1)),
          new Date(startTime),
        ),
      },
      ...Array(count - 2)
        .fill()
        .map((_, index) => ({
          hour: addHours(startOfHour(new Date(startTime)), index + 1),
          durationSeconds: 3600,
        })),
      {
        hour: startOfHour(new Date(endTime)),
        durationSeconds: differenceInSeconds(
          new Date(endTime),
          startOfHour(new Date(endTime)),
        ),
      },
    ];
  }
}

export function getHourMap(startTime, endTime) {
  return new Map(
    Array(24)
      .fill()
      .map((_, index) => [
        index,
        new Map(
          Array(differenceInDays(endTime, startTime) + 1)
            .fill()
            .map((_, index) => [addDays(startTime, index), 0]),
        ),
      ]),
  );
}

export function pick(object, names) {
  return names.reduce((result, name) => {
    if (name in object) {
      result[name] = object[name];
    }
    return result;
  }, {});
}

export function humanizeHours(hours) {
  return shortHumanizer(Math.round(hours * 3600) * 1000);
}

export function getBackgroundColor(color, mode) {
  if (!color) return null;

  return mode === 'dark' ? darken(color, 0.75) : lighten(color, 0.75);
}

export function getHoverBackgroundColor(color, mode) {
  if (!color) return null;

  return mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);
}

export function getStatusColor(status) {
  switch (status) {
    case 'opened':
      return red[700];
    case 'assigned':
      return orange[700];
    case 'attended':
      return green[700];
    case 'closed':
      return '#000';
    case 'emergencyEquipmentOn':
      return indigo[700];
    case 'ignitionOff':
      return grey[500];
    case 'ignitionOn':
      return '#000';
    case 'malfunctionIndicatorLightOn':
      return amber[700];
    case 'active':
      return blue[700];
    case 'inactive':
      return blue[100];
    case 'unavailable':
      return purple[700];
    case 'available':
      return '#000';
    case 'ememrgency':
      return yellow[700];
    case 'unknown':
      return cyan[700];
    case 'default':
      return '#000';
    default:
      return null;
  }
}

export function getStatusForeColor(status) {
  switch (status) {
    case 'opened':
      return '#fff';
    case 'assigned':
      return '#fff';
    case 'attended':
      return '#fff';
    case 'closed':
      return '#fff';
    case 'emergencyEquipmentOn':
      return '#fff';
    case 'ignitionOff':
      return '#fff';
    case 'ignitionOn':
      return '#fff';
    case 'malfunctionIndicatorLightOn':
      return '#fff';
    case 'active':
      return '#fff';
    case 'inactive':
      return '#fff';
    case 'unavailable':
      return '#fff';
    case 'available':
      return '#fff';
    case 'ememrgency':
      return '#000';
    case 'unknown':
      return '#fff';
    case 'default':
      return '#fff';
    default:
      return null;
  }
}

export function kilometresToMiles(kilometres, precision) {
  return round(kilometres * 0.62137119, precision);
}

export function parseIfJSON(string) {
  try {
    return JSON.parse(string, reviveDate);
  } catch (_) {
    return string;
  }
}

export const featureSortFn =
  ({ sortBy, sortDesc }) =>
  (a, b) => {
    const aValue = get((sortDesc ? b : a).properties, sortBy);
    const bValue = get((sortDesc ? a : b).properties, sortBy);

    if (aValue < bValue) {
      return -1;
    }
    if (aValue > bValue) {
      return 1;
    }
    return 0;
  };

export const transformRequest = (style) => (url) => {
  const selectedStyle = styles.find((s) => s.path === style) ?? styles[0];
  if (selectedStyle.source === 'ORDNANCE_SURVEY') {
    if (url.includes('https://api.os.uk')) {
      if (!/[?&]key=/.test(url)) {
        url += '?key=' + ordnanceSurveyKey;
      }
      return {
        url: url + '&srs=3857',
      };
    }
  } else {
    return null;
  }
};

export async function downloadData(url, filename, pipeline, columns) {
  const objectURL = URL.createObjectURL(
    await api
      .post(url, {
        headers: {
          accept: 'text/csv',
        },
        json: {
          filename,
          pipeline,
          columns,
        },
      })
      .blob(),
  );
  const link = document.createElement('a');
  link.href = objectURL;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  URL.revokeObjectURL(objectURL);
}

export const sortFn = (sortBy, sortDesc) => (a, b) => {
  if (typeof a[sortBy] === 'string' || typeof b[sortBy] === 'string') {
    if (sortDesc) {
      return (b[sortBy] ?? 'Ω').localeCompare(a[sortBy] ?? 'Ω');
    } else {
      return (a[sortBy] ?? 'Ω').localeCompare(b[sortBy] ?? 'Ω');
    }
  }

  if (sortDesc) {
    return b[sortBy] ?? 0 - a[sortBy] ?? 0;
  } else {
    return a[sortBy] ?? 0 - b[sortBy] ?? 0;
  }
};

function descendingComparator(a, b, orderBy) {
  if (get(b, orderBy, '') < get(a, orderBy, '')) {
    return -1;
  }
  if (get(b, orderBy, '') > get(a, orderBy, '')) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function isNotTooBig(value) {
  const blob = new Blob([JSON.stringify(value)], {
    type: 'application/json',
  });

  if (blob.size > maxUploadSize) {
    return 'Content greater than 5MB';
  }

  return true;
}

export function epochHoursToHistogram(
  startEpochHour,
  endEpochHour,
  epochHours,
) {
  let availabilityAtEpochHour = {};
  let histogram = {};

  // epochHours looks like this:
  // [[442668, 442669], [442668, 442669, 442670], ...]
  // (an epoch hour is a specific hour represented as hours after 1/1/1970,
  // e.g. 1 is 1/1/1970 01:00, 24 is 2/1/1970 00:00)
  // the first array is the epoch hours that the first vehicle
  // was at this location, the second array is the hours the second vehicle
  // was at this location etc.
  // sum these up so we know how many vehicles were there per hour
  // {442668: 2, 442669: 2, 442670: 1, ...}
  epochHours.forEach((hourArray) => {
    hourArray.forEach(({ $numberDecimal: hour }) => {
      // there will be lots of overlap (stops that started before our start)
      // so only include ones within our range
      if (startEpochHour <= hour && hour <= endEpochHour) {
        availabilityAtEpochHour[hour] =
          (availabilityAtEpochHour[hour] || 0) + 1;
      }
    });
  });

  // for the time range, get a histogram of the instances a particular
  // count was at the location e.g.
  // let results = {
  //   "0": 1, // there were no vehicles at the location for 1 hour
  //   "2": 3, // there were 2 vehicles at the location for 3 hours
  //   "3": 5, // there were 3 vehicles at the location for 5  hours
  //   "4": 8  // there were 4 vehicles at the location for 8 hours
  // }
  let maxInstances = -1;
  for (let i = startEpochHour; i < endEpochHour; i++) {
    // if there's no vehicles at this hour, set it to 0
    if (!availabilityAtEpochHour[i]) {
      availabilityAtEpochHour[i] = 0;
    }
    let instances = availabilityAtEpochHour[i];
    if (instances > maxInstances) {
      maxInstances = instances;
    }

    histogram[instances] = (histogram[instances] || 0) + 1;
  }

  // make sure there are no missed ones e.g. 1,2,3,5 should have 4 in there
  for (let i = 0; i <= maxInstances; i++) {
    if (!histogram[i]) {
      histogram[i] = 0;
    }
  }

  // transform it a bit for recharts...
  return [
    Object.keys(availabilityAtEpochHour)
      .sort()
      .map((epochHour) => ({
        hour: epochHour * 3600 * 1000, // Convert Unix timestamp to Epoch hour
        date: format(epochHour * 3600 * 1000, 'dd/MM/yyyy'),
        count: availabilityAtEpochHour[epochHour],
      })),
    Object.keys(histogram)
      .sort((a, b) => a - b) // sort numeric keys 1, 2, 10 instead of 1, 10, 2
      .map((count) => ({
        count,
        hours: histogram[count],
      })),
  ];
}

export function getValues(object, path) {
  const segments = path.split('.');
  if (segments[0] in object) {
    const child = object[segments[0]];
    if (segments.length === 1) {
      if (child instanceof Array) {
        return child;
      } else {
        return [child];
      }
    } else if (child instanceof Array) {
      return [].concat(
        ...child.map((entry) => getValues(entry, segments.slice(1).join('.'))),
      );
    } else {
      return getValues(child, segments.slice(1).join('.'));
    }
  } else {
    return [];
  }
}

export function getAllValues(array, path) {
  const rawValues = Array.from(
    new Set([].concat(...array.map((object) => getValues(object, path)))),
  )
    .filter(Boolean)
    .sort();

  return rawValues;
}

function filterVehicle(filter, properties) {
  if (
    (filter.registrationNumber || []).length > 0 &&
    !filter.registrationNumber.includes(properties.vehicle?.registrationNumber)
  ) {
    return false;
  }

  if (
    (filter.fleetNumber || []).length > 0 &&
    !filter.fleetNumber.includes(properties.vehicle?.fleetNumber)
  ) {
    return false;
  }

  if (
    (filter.role || []).length > 0 &&
    !filter.role.includes(properties.vehicle?.role)
  ) {
    return false;
  }

  if (
    (filter.homeStation || []).length > 0 &&
    !filter.homeStation.includes(properties.vehicle?.homeStation)
  ) {
    return false;
  }

  if (
    (filter.groupCodes || []).length > 0 &&
    !(properties.vehicle?.groupCodes || []).some((value) =>
      filter.groupCodes.includes(value),
    )
  ) {
    return false;
  }

  return true;
}

function filterPerson(filter, properties) {
  if (
    (filter.staffNumber || []).length > 0 &&
    !filter.staffNumber.includes(properties.person?.staffNumber)
  ) {
    return false;
  }

  if (
    (filter.collarNumber || []).length > 0 &&
    !filter.collarNumber.includes(properties.person?.collarNumber)
  ) {
    return false;
  }

  if (
    (filter.category || []).length > 0 &&
    !filter.category.includes(properties.person?.category)
  ) {
    return false;
  }

  if (
    (filter.role || []).length > 0 &&
    !filter.role.includes(properties.person?.role)
  ) {
    return false;
  }

  if (
    (filter.groupCodes || []).length > 0 &&
    !(properties.person?.groupsCodes || []).some((value) =>
      filter.groupCodes.includes(value),
    )
  ) {
    return false;
  }

  return true;
}

function filterLocation(filter, properties) {
  if (
    (filter.type || []).length > 0 &&
    !filter.type.includes(properties.type)
  ) {
    return false;
  }

  if (
    (filter.subtype || []).length > 0 &&
    !filter.subtype.includes(properties.subtype)
  ) {
    return false;
  }

  if (
    (filter.groupCodes || []).length > 0 &&
    !(properties.groupsCodes || []).some((value) =>
      filter.groupCodes.includes(value),
    )
  ) {
    return false;
  }

  return true;
}

function filterIncident(filter, properties) {
  if (
    (filter.grade || []).length > 0 &&
    !filter.grade.includes(properties.grade)
  ) {
    return false;
  }

  if (
    (filter.code || []).length > 0 &&
    !filter.code.includes(properties.type?.code)
  ) {
    return false;
  }

  if (
    (filter.type || []).length > 0 &&
    !filter.type.includes(properties.type?.name)
  ) {
    return false;
  }

  return true;
}

function filterObjective(filter, properties) {
  if (
    (filter.occurrenceNumber || []).length > 0 &&
    !filter.occurrenceNumber.includes(properties.occurrenceNumber)
  ) {
    return false;
  }

  if (
    (filter.microbeats || []).length > 0 &&
    !(properties.microbeats || []).some((value) =>
      filter.microbeats.includes(value),
    )
  ) {
    return false;
  }

  return true;
}

function filterEvent(filter, properties) {
  if (
    (filter.registrationNumber || []).length > 0 &&
    !filter.registrationNumber.includes(properties.vehicle?.registrationNumber)
  ) {
    return false;
  }

  if (
    (filter.fleetNumber || []).length > 0 &&
    !filter.fleetNumber.includes(properties.vehicle?.fleetNumber)
  ) {
    return false;
  }

  if (
    (filter.role || []).length > 0 &&
    !filter.role.includes(properties.vehicle?.role)
  ) {
    return false;
  }

  if (
    (filter.homeStation || []).length > 0 &&
    !filter.homeStation.includes(properties.vehicle?.homeStation)
  ) {
    return false;
  }

  if (
    (filter.groupCodes || []).length > 0 &&
    !(properties.vehicle?.groupsCodes || []).some((value) =>
      filter.groupCodes.includes(value),
    )
  ) {
    return false;
  }

  return true;
}

export function filterType(type, filter, properties) {
  switch (type) {
    case 'vehicles':
      return filterVehicle(filter, properties);
    case 'people':
      return filterPerson(filter, properties);
    case 'locations':
      return filterLocation(filter, properties);
    case 'incidents':
      return filterIncident(filter, properties);
    case 'objectives':
      return filterObjective(filter, properties);
    case 'events':
      return filterEvent(filter, properties);
    default:
      return true;
  }
}

export function isAuthorised(authorisation, type, canEdit) {
  if (!authorisation) {
    return false;
  }

  if (type === 'resources') {
    return canEdit
      ? (authorisation.vehicles || {}).write ||
          (authorisation.people || {}).write ||
          (authorisation.vehicles || {}).write ||
          (authorisation.telematicsBoxes || {}).write
      : (authorisation.vehicles || {}).read ||
          (authorisation.people || {}).read ||
          (authorisation.vehicles || {}).read ||
          (authorisation.telematicsBoxes || {}).read;
  } else {
    return canEdit
      ? (authorisation[type] || {}).write
      : (authorisation[type] || {}).read;
  }
}

export const getGroupType = (type) => groupTypeAliases[type] ?? type;

export function hasNoValues(obj) {
  if (typeof obj === 'string' || typeof obj === 'number') {
    return !obj;
  } else if (obj instanceof Date) {
    return false; // Date objects are never considered "empty"
  } else if (typeof obj === 'object' && obj !== null) {
    for (const key in obj) {
      if (!hasNoValues(obj[key])) {
        return false;
      }
    }
  }

  return true;
}

export const hourOptions = [...Array(24).keys()];

export const dayOptions = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

export const measureTypes = ['total', 'average', 'daily'];

export const activityCompareFn =
  (key = 'group', desc = false) =>
  (a, b) => {
    if (typeof a[key] === 'string' && typeof b[key] === 'string') {
      return desc ? b[key].localeCompare(a[key]) : a[key].localeCompare(b[key]);
    }

    return desc ? b[key] - a[key] : a[key] - b[key];
  };

export const formatGroupBy =
  (groupBy) =>
  (value = '') => {
    switch (groupBy) {
      case 'all':
        return 'All';
      case 'date':
        return value instanceof Date ? format(value, 'dd/MM/yyyy') : value;
      case 'month':
        return value instanceof Date ? format(value, 'MM/yyyy') : value;
      default:
        return value?.toString() ?? 'None';
    }
  };

export const offsetToTime = (offset = 0, offsetHours = 0) =>
  format(
    new Date('2000-01-01T00:00:00Z').setHours(offsetHours + offset),
    'HH:mm',
  );

export const formatMinutes = (value) => shortHumanizer(value * 60 * 1000);
