import {
  FETCH_LIVE_ESTIMATED_TIME_OF_ARRIVAL,
  FETCH_LIVE_VEHICLE,
} from '@/actions/types';
import { TagControl } from '@/components/controls';
import {
  useDocumentTitle,
  useOptionLookup,
  useOptionValues,
  usePrevious,
} from '@/hooks';
import { startCase } from '@/utils';
import {
  dioStates,
  etaRequestDelay,
  showEstimatedTimeOfArrival,
  speedRuleLabels,
} from '@/utils/config';
import {
  DirectionsCar as DirectionsCarIcon,
  Edit as EditIcon,
  LocationSearching as FollowIcon,
  Navigation as NavigationIcon,
  RvHookup as RvHookupIcon,
  VolumeUp as VolumeUpIcon,
  VpnKey as VpnKeyIcon,
  Warning as WarningIcon,
  WbIncandescent as WbIncandescentIcon,
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Table,
  TableBody,
  Tooltip,
  Typography,
} from '@mui/material';
import { amber, blue, grey, red } from '@mui/material/colors';
import { format, isAfter, subSeconds } from 'date-fns';
import {
  AlarmLight as AlarmLightIcon,
  Engine as EngineIcon,
  CarLightHigh as HeadlightsFlashIcon,
} from 'mdi-material-ui';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toLabelAccessors } from '../utilities';
import { CallSignLiveListItem } from './CallSignLiveListItem';
import { IncidentLiveListItem } from './IncidentLiveListItem';
import { ItemAvatar } from './ItemAvatar';
import { ItemRows } from './ItemRows';
import { PeopleLiveListItem } from './PeopleLiveListItem';
import { StatusDot } from './StatusDot';

const knownDioStates = [
  'beaconsOn',
  'sirensOn',
  'amberLightsOn',
  'trailerOn',
  'rearBlueLightsOn',
  'rearRedLightsOn',
  'headlightsFlashOn',
  'malfunctionIndicatorLightOn',
];

export function VehicleLiveItem({
  item,
  onFollowToggle,
  followedIdsByType,
  onSubItemClick,
  onSubItemHover,
  hoveredId,
}) {
  useDocumentTitle(
    ['IR3', 'Live', 'Vehicles', item?.registrationNumber]
      .filter(Boolean)
      .join(' | '),
  );
  const navigate = useNavigate();
  const vehicleRecord = useSelector(
    (state) => state.live.vehicleRecordsById[item.id],
  );
  const vehicleGroups = useOptionValues('vehicleGroup');
  const groupTypes = useOptionLookup('groupType');

  const dispatch = useDispatch();
  const prevId = usePrevious(item.id);
  useEffect(() => {
    if (prevId !== item.id && !vehicleRecord) {
      dispatch({
        type: FETCH_LIVE_VEHICLE,
        payload: item.id,
      });
    }
  }, [dispatch, item.id, prevId, vehicleRecord]);

  const { speeding, reverseGeocode, currentSpeedRules } = item;
  let speedLimit = undefined;
  const knownLimit = !(reverseGeocode?.unknownLimit ?? true);
  if (knownLimit) {
    speedLimit = Math.round(
      reverseGeocode?.speedLimitKilometresPerHour * 0.62137119,
    );
  }

  const incidents = useSelector((state) => state.live.incidents);
  const incident = useMemo(() => {
    return (
      item.assignments?.incident?.number &&
      (incidents[item.assignments.incident.number] || {
        id: item.assignments.incident.number,
      })
    );
  }, [item, incidents]);

  const callSigns = useSelector((state) => state.live.callSigns);
  const callSign = callSigns[item.assignments?.callSign?.code];

  // Get Estimated time of arrival
  const estimatedTimeOfArrival = useSelector(
    (state) => state.live.estimatedTimeOfArrivals,
  ).find((eta) => eta?.vin === item.id);
  const [nextEtaRequest, setNextEtaRequest] = useState(
    subSeconds(new Date(), 1),
  ); // subtract one second to make the first request
  const previousIncident = usePrevious(incident);

  useEffect(() => {
    if (showEstimatedTimeOfArrival) {
      const callSignStatus = item.assignments?.callSign?.status;
      const checkCallSignsStatus = callSignStatus !== 'At Scene';

      // if incident assigned set the delay to make immediate ETA request
      if (previousIncident === undefined && incident) {
        setNextEtaRequest(subSeconds(new Date(), 1));
      }

      if (
        incident &&
        checkCallSignsStatus &&
        isAfter(new Date(), new Date(nextEtaRequest))
      ) {
        setNextEtaRequest(new Date() + etaRequestDelay * 1000);
        dispatch({
          type: FETCH_LIVE_ESTIMATED_TIME_OF_ARRIVAL,
          payload: { vehicle: item, incident },
        });
      }
    }
  }, [dispatch, item, incident, nextEtaRequest, previousIncident]);

  const people = useSelector((state) => state.live.people);
  const driver = item.ignitionOn ? item.driver : item.lastDriver;
  const matchedDriver = driver && people[driver.code];
  if (matchedDriver) {
    matchedDriver.id = matchedDriver.code;
  }

  const type = 'vehicles';
  const following = followedIdsByType?.[type]?.[item.id];

  function NonMatchDriverItem() {
    const nonMatchItem = { ...item, status: 'Unclassified' };
    return (
      <ListItem>
        <ListItemAvatar>
          <ItemAvatar item={nonMatchItem} type="people" />
        </ListItemAvatar>
        <ListItemText
          component="div"
          primary={item.driverName}
          secondary="Not on radio"
        />
      </ListItem>
    );
  }

  function getDriverSection(driverName, driver) {
    driver = matchedDriver || driver;
    if (!driver) {
      return null;
    }

    let title = driverName;
    if (title === null || title.match(/^ *$/) !== null) {
      title = driver.code;
    }

    function handleClick() {
      if (matchedDriver) {
        onSubItemClick({ id: matchedDriver.id, type: 'people' });
      } // o/w nothing to nav to
    }

    return !matchedDriver ? (
      <NonMatchDriverItem item={item} />
    ) : (
      <PeopleLiveListItem
        onClick={matchedDriver ? handleClick : undefined}
        onHoverItem={matchedDriver ? onSubItemHover : undefined}
        item={matchedDriver || { id: '_nomatch', name: driverName, role: '' }}
        highlighted={hoveredId === driver.code}
        onFollowToggle={onFollowToggle}
        followedIdsByType={followedIdsByType}
        hideFollow={!matchedDriver}
      />
    );
  }

  const speedRulesTooltip = item.currentSpeedRules
    ?.map((r) => speedRuleLabels?.[r] ?? r)
    .join(', ');

  const sections = {
    '': [
      {
        label: 'Status',
        value: <StatusDot item={item} type={type} />,
      },
      {
        label: 'Last poll time',
        value:
          item.lastPollTime &&
          format(new Date(item.lastPollTime), 'dd/MM/yyyy HH:mm:ss'),
      },
      {
        label: 'Last ignition off time',
        value:
          item.lastIgnitionOffTime &&
          format(new Date(item.lastIgnitionOffTime), 'dd/MM/yyyy HH:mm:ss'),
      },
      ...Object.keys(dioStates)
        .filter((key) => !knownDioStates.includes(key)) // filter out ones covered by icons
        .map(
          (key) =>
            item[key] !== undefined && {
              label: dioStates[key],
              value: item[key] ? 'On' : 'Off',
            },
        )
        .filter(Boolean),
      // {
      //   label: 'Speed',
      //   value: round(item.speedKilometresPerHour * 0.62137199, 2) + ' MPH',
      //   valueStyle: item.speeding ? { color: red[700] } : undefined,
      // },
      item.speedLimitTag && {
        label: 'Speed limit',
        value: item.speedLimitTag.toUpperCase(),
      },
      item.currentSpeedRules?.length > 0 && {
        label: `Speed ${
          item.currentSpeedRules.length > 1 ? 'rules' : 'rule'
        } broken`,
        value: speedRulesTooltip,
      },
      showEstimatedTimeOfArrival &&
        estimatedTimeOfArrival &&
        incident && {
          label: 'Estimated Time of Arrival',
          value: estimatedTimeOfArrival.time ? estimatedTimeOfArrival.eta : '',
        },
    ].filter(Boolean),
    details: [
      {
        label: 'Description',
        value: [item.colour, item.make, item.model].filter(Boolean).join(' '),
      },
      { label: 'Role', value: item.role },
      { label: 'Home Station', value: item.homeStation },
      // these are already in the avatar, no need to repeat
      // { label: "Fleet Number", value: item.fleetNumber },
      // { label: "Registration", value: item.registrationNumber },
      { label: 'Type', value: item.type },
      // these are covered by description
      // { label: 'Make', value: item.make },
      // { label: 'Model', value: item.model },
      // { label: 'Colour', value: item.colour },
      item.equipment?.length > 0
        ? { label: 'Equipment', value: item.equipment?.join(', ') || '' }
        : false,
      { label: 'IMEI', value: item.telematicsBoxImei },
    ].filter(Boolean),
    groups: vehicleRecord?.groupAncestors?.map((group) => ({
      label: groupTypes[group.type],
      value: group.name,
    })),
    categories: Object.entries(item.groups ?? []).map(([type, values]) => ({
      label:
        type in vehicleGroups ? vehicleGroups[type].label : startCase(type),
      value: values
        .map(
          (value) =>
            vehicleGroups[type]?.values.find((entry) => entry.value === value)
              ?.label ?? value,
        )
        .join(', '),
    })),
    driver: item.ignitionOn && getDriverSection(item.driverName, item.driver),
    lastDriver:
      !item.ignitionOn && getDriverSection(item.driverName, item.lastDriver),
    locations: toLabelAccessors(item.currentLocations, 'type', 'name'),
  };

  const link = `/resources/${type}/${item.id}`;

  return (
    <Card sx={{ m: 1 }}>
      <CardHeader
        avatar={
          <Avatar src={vehicleRecord?.picture}>
            <DirectionsCarIcon />
          </Avatar>
        }
        title={item.registrationNumber}
        subheader={item.fleetNumber}
        action={
          <Stack direction="row" alignItems="center">
            <Tooltip title="Edit">
              <IconButton onClick={() => navigate(link)}>
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title={following ? 'Stop following' : 'Follow'}>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  onFollowToggle('vehicles', item.id);
                }}
              >
                <FollowIcon
                  titleAccess="Follow"
                  fontSize="small"
                  color={following ? 'primary' : 'disabled'}
                />
              </IconButton>
            </Tooltip>
          </Stack>
        }
      />
      <CardContent>
        <Stack direction="row" spacing={1}>
          <NavigationIcon
            // fromCharCode so degrees symbol doesn't stop chrome debugger mapping
            titleAccess={
              'Heading ' + item.headingDegrees + String.fromCharCode(176)
            }
            style={{
              transform: `rotate(${item.headingDegrees}deg)`,
              transformOrigin: ['50%', '50%'],
            }}
          />
          {item.ignitionOn !== undefined && (
            <VpnKeyIcon
              titleAccess={'Ignition ' + (item.ignitionOn ? 'on' : 'off')}
              htmlColor={item.ignitionOn ? amber[700] : grey[400]}
            />
          )}
          {item.sirensOn !== undefined && (
            <VolumeUpIcon
              titleAccess={'Siren ' + (item.sirensOn ? 'on' : 'off')}
              htmlColor={item.sirensOn ? amber[700] : grey[400]}
            />
          )}
          {item.beaconsOn !== undefined && (
            <AlarmLightIcon
              titleAccess={'Beacons ' + (item.beaconsOn ? 'on' : 'off')}
              htmlColor={item.beaconsOn ? amber[700] : grey[400]}
            />
          )}
          {item.headlightsFlashOn !== undefined && (
            <HeadlightsFlashIcon
              titleAccess={
                'Headlights flash ' + (item.headlightsFlashOn ? 'on' : 'off')
              }
              htmlColor={item.headlightsFlashOn ? amber[700] : grey[400]}
            />
          )}
          {item.malfunctionIndicatorLightOn !== undefined && (
            <EngineIcon
              titleAccess={
                'Malfunction Indicator Light ' +
                (item.malfunctionIndicatorLightOn ? 'on' : 'off')
              }
              htmlColor={
                item.malfunctionIndicatorLightOn ? amber[700] : grey[400]
              }
            />
          )}
          {item.accelerometerAlert !== undefined && (
            <WarningIcon
              htmlColor={item.accelerometerAlert ? amber[700] : grey[400]}
            />
          )}

          {item.amberLightsOn !== undefined && (
            <AlarmLightIcon
              titleAccess={'Amber lights ' + item.amberLightsOn ? 'on' : 'off'}
              htmlColor={item.amberLightsOn ? amber[700] : grey[400]}
            />
          )}
          {item.trailerOn !== undefined && ( //nature rv_hookup
            <RvHookupIcon
              titleAccess={'Trailer ' + (item.trailerOn ? 'on' : 'off')}
              htmlColor={item.trailerOn ? amber[700] : grey[400]}
            />
          )}
          {item.rearBlueLightsOn !== undefined && (
            <WbIncandescentIcon
              titleAccess={
                'Rear Blue Lights ' + (item.rearBlueLightsOn ? 'on' : 'off')
              }
              htmlColor={item.rearBlueLightsOn ? blue[500] : grey[400]}
            />
          )}
          {item.rearRedLightsOn !== undefined && (
            <WbIncandescentIcon
              titleAccess={
                'Rear Red Lights ' + (item.rearRedLightsOn ? 'on' : 'off')
              }
              htmlColor={item.rearRedLightsOn ? red[500] : grey[400]}
            />
          )}
          {/* {item.currentSpeedRules?.length > 0 && (
            <div
              style={{
                color: red[700],
                display: 'flex',
                alignItems: 'end',
              }}
              title={speedRulesTooltip}
            >
              <SpeedometerIcon
                title={speedRulesTooltip}
                className={classes.statusIconLarge}
                htmlColor={red[700]}
                style={{ marginRight: 0 }}
              />
              {item.currentSpeedRules?.length > 1 &&
                'x' + item.currentSpeedRules.length}
            </div>
          )} */}
          <Box sx={{ flex: 1 }} />
          <Tooltip title={speedRulesTooltip}>
            <Chip
              size="small"
              sx={{
                '.MuiChip-avatarSmall': {
                  bgcolor: 'background.default',
                  color: speeding ? 'error.main' : undefined,
                  marginLeft: '3px',
                },
              }}
              color={currentSpeedRules?.length ? 'error' : undefined}
              avatar={
                speedLimit && (speeding || currentSpeedRules?.length) ? (
                  <Avatar>
                    <Box
                      sx={{
                        color: currentSpeedRules?.length && 'text.primary',
                      }}
                    >
                      {speedLimit}
                    </Box>
                  </Avatar>
                ) : undefined
              }
              label={`${Math.round(
                item.speedKilometresPerHour * 0.62137119,
              )} MPH`}
            />
          </Tooltip>
        </Stack>
        <TagControl item={item} type={type} sx={{ py: 2 }} label="Tags" />
        {item.assignments && (
          <Fragment>
            {item.assignments.callSign?.code && (
              <Fragment>
                <Typography variant="subtitle2" color="textSecondary">
                  Call Sign
                </Typography>
                <CallSignLiveListItem
                  onClick={onSubItemClick}
                  onHoverItem={onSubItemHover}
                  item={callSign}
                />
              </Fragment>
            )}
            {incident && (
              <Fragment>
                <Typography variant="subtitle2" color="textSecondary">
                  Assigned Incident
                </Typography>
                <IncidentLiveListItem
                  onClick={onSubItemClick}
                  onHoverItem={onSubItemHover}
                  onFollowToggle={onFollowToggle}
                  followedIdsByType={followedIdsByType}
                  item={incident}
                  highlighted={hoveredId === incident.id}
                />
              </Fragment>
            )}
          </Fragment>
        )}
        {Object.keys(sections)
          .filter(
            (key) =>
              sections[key] &&
              (!Array.isArray(sections[key]) || sections[key].length > 0),
          )
          .map((key) => (
            <Fragment key={key}>
              <Typography variant="subtitle2" color="textSecondary">
                {startCase(key)}
              </Typography>
              {Array.isArray(sections[key]) ? (
                <Table size="small" sx={{ mt: 1, mb: 2 }}>
                  <TableBody>{ItemRows(sections[key], item)}</TableBody>
                </Table>
              ) : (
                sections[key]
              )}
            </Fragment>
          ))}
      </CardContent>
    </Card>
  );
}
