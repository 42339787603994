import { getStatusIconColours } from '@/utils/mapStyles';
import { pluralToSingularTypeMap } from '../constants';
import { Box, Stack } from '@mui/material';
import { startCase } from '@/utils';

export function StatusDot({ item, type, sx }) {
  const status = item.iconStatus ?? item.status;
  type = pluralToSingularTypeMap[type] ?? type;
  const [, bgcolor, borderColor] = getStatusIconColours(type, status);

  return (
    <Stack direction="row" gap={1} alignItems="center">
      <Box
        sx={{
          bgcolor,
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: borderColor,
          borderRadius: 8,
          width: 16,
          height: 16,
          ...sx,
        }}
      />
      {startCase(status)}
    </Stack>
  );
}
