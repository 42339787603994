import { api } from '@/apis';
import { useQuery } from '@tanstack/react-query';
import { awaitObjectPromises, collections, sections } from './utils';

export function useResourceOptions() {
  return useQuery({
    queryKey: ['resourceOptions'],
    queryFn: async () => {
      const { value: searchFields } = await api
        .get('settings/searchFields_v2')
        .json();

      const values = {
        RESOURCE: await awaitObjectPromises(
          Object.entries(searchFields ?? {}).reduce(
            (accumulator, [resourceType, fields]) => ({
              ...accumulator,
              [resourceType]: api
                .post(`pipeline/${collections[resourceType]}`, {
                  json: [
                    ...fields
                      .filter(({ source: { type } }) => type === 'RESOURCE')
                      .map((field) => ({
                        $unwind: {
                          path: `$${field.key}`,
                          preserveNullAndEmptyArrays: true,
                        },
                      })),
                    {
                      $group: {
                        _id: null,
                        ...fields
                          .filter(({ source: { type } }) => type === 'RESOURCE')
                          .reduce(
                            (accumulator, field) => ({
                              ...accumulator,
                              [field.key.replace('.', '_')]: {
                                $addToSet: `$${field.key}`,
                              },
                            }),
                            {},
                          ),
                      },
                    },
                    {
                      $project: {
                        _id: false,
                        ...fields
                          .filter(({ source: { type } }) => type === 'RESOURCE')
                          .reduce(
                            (accumulator, field) => ({
                              ...accumulator,
                              [field.key]: {
                                $map: {
                                  input: {
                                    $sortArray: {
                                      input: `$${field.key.replace('.', '_')}`,
                                      sortBy: 1,
                                    },
                                  },
                                  as: 'value',
                                  in: { label: '$$value', value: '$$value' },
                                },
                              },
                            }),
                            {},
                          ),
                      },
                    },
                  ],
                })
                .json()
                .then((data) => data[0]),
            }),
            {},
          ),
        ),
        OPTION: await awaitObjectPromises(
          Object.entries(searchFields ?? {}).reduce(
            (accumulator, [resourceType, fields]) => ({
              ...accumulator,
              [resourceType]: awaitObjectPromises(
                fields
                  .filter(({ source: { type } }) => type === 'OPTION')
                  .reduce(
                    (resourceAccumulator, { key, source: { name } }) => ({
                      ...resourceAccumulator,
                      [key]: api
                        .post('pipeline/options', {
                          json: [
                            { $match: { name } },
                            {
                              $project: {
                                _id: false,
                                label: true,
                                value: true,
                              },
                            },
                            { $sort: { label: 1 } },
                          ],
                        })
                        .json(),
                    }),
                    {},
                  ),
              ),
            }),
            {},
          ),
        ),
        OPTION_VALUE: await awaitObjectPromises(
          Object.entries(searchFields ?? {}).reduce(
            (accumulator, [resourceType, fields]) => ({
              ...accumulator,
              [resourceType]: awaitObjectPromises(
                fields
                  .filter(({ source: { type } }) => type === 'OPTION_VALUE')
                  .reduce(
                    (
                      resourceAccumulator,
                      { key, source: { name, value } },
                    ) => ({
                      ...resourceAccumulator,
                      [key]: api
                        .post('pipeline/options', {
                          json: [
                            { $match: { name, value } },
                            {
                              $project: {
                                _id: false,
                                values: {
                                  $sortArray: {
                                    input: '$values',
                                    sortBy: { label: 1 },
                                  },
                                },
                              },
                            },
                          ],
                        })
                        .json()
                        .then((data) => data[0].values),
                    }),
                    {},
                  ),
              ),
            }),
            {},
          ),
        ),
        GROUPED_OPTION: await awaitObjectPromises(
          Object.entries(searchFields ?? {}).reduce(
            (accumulator, [resourceType, fields]) => ({
              ...accumulator,
              [resourceType]: awaitObjectPromises(
                fields
                  .filter(({ source: { type } }) => type === 'GROUPED_OPTION')
                  .reduce(
                    (resourceAccumulator, { key, source: { name } }) => ({
                      ...resourceAccumulator,
                      [key]: api
                        .post('pipeline/options', {
                          json: [
                            { $match: { name } },
                            { $unwind: '$values' },
                            {
                              $project: {
                                _id: false,
                                label: '$values.label',
                                value: '$values.value',
                                group: '$label',
                              },
                            },
                            { $sort: { label: 1 } },
                          ],
                        })
                        .json(),
                    }),
                    {},
                  ),
              ),
            }),
            {},
          ),
        ),
        COLLECTION: await awaitObjectPromises(
          Object.entries(searchFields ?? {}).reduce(
            (accumulator, [resourceType, fields]) => ({
              ...accumulator,
              [resourceType]: awaitObjectPromises(
                fields
                  .filter(({ source: { type } }) => type === 'COLLECTION')
                  .reduce(
                    (
                      resourceAccumulator,
                      { key, source: { collection, label, value, filter } },
                    ) => ({
                      ...resourceAccumulator,
                      [key]: api
                        .post(`pipeline/${collection}`, {
                          json: [
                            filter && { $match: filter },
                            { $project: { _id: false, label, value } },
                            { $sort: { label: 1 } },
                          ].filter(Boolean),
                        })
                        .json(),
                    }),
                    {},
                  ),
              ),
            }),
            {},
          ),
        ),
        RANGE: Object.entries(searchFields ?? {}).reduce(
          (accumulator, [resourceType, fields]) => ({
            ...accumulator,
            [resourceType]: fields
              .filter(({ source: { type } }) => type === 'RANGE')
              .reduce(
                (resourceAccumulator, { key, source: { min, max } }) => ({
                  ...resourceAccumulator,
                  [key]: [...Array(max - min + 1).keys()].map((value) => ({
                    label: (value + min).toString(),
                    value: value + min,
                  })),
                }),
                {},
              ),
          }),
          {},
        ),
      };

      const value = Object.entries(sections).reduce(
        (accumulator, [alias, { label, actual }]) => ({
          ...accumulator,
          [alias]: {
            label,
            fields: (searchFields?.[actual] ?? []).reduce(
              (accumulator, { key, label, source: { type } }) => ({
                ...accumulator,
                [key]: {
                  label,
                  values: (values[type][actual]?.[key] ?? []).map(
                    ({ label, value, group }) => ({
                      label,
                      value,
                      group,
                    }),
                  ),
                },
              }),
              {},
            ),
          },
        }),
        {},
      );

      return value;
    },
    placeholderData: {},
    staleTime: 1000 * 60 * 60 * 8,
  });
}
