import { api } from '@/apis';
import { log } from '@/utils';
import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';

export function useLocationOccupancyChanges({ query }) {
  return useQuery({
    queryKey: ['locationOccupancyChanges', query],
    queryFn: async ({ signal }) => {
      const json = [
        { $match: query },
        {
          $project: {
            changes: [
              {
                time: { $max: ['$startTime', query.endTime.$gte] },
                change: 1,
                isHome: { $eq: ['$location.code', '$vehicle.homeStation'] },
              },
              {
                time: { $min: ['$endTime', query.startTime.$lt] },
                change: {
                  $cond: [{ $gte: ['$endTime', query.startTime.$lt] }, 0, -1],
                },
                isHome: { $eq: ['$location.code', '$vehicle.homeStation'] },
              },
            ],
          },
        },
        { $unwind: '$changes' },
        {
          $group: {
            _id: '$changes.time',
            residents: {
              $sum: {
                $cond: ['$changes.isHome', '$changes.change', 0],
              },
            },
            visitors: {
              $sum: {
                $cond: [{ $not: '$changes.isHome' }, '$changes.change', 0],
              },
            },
          },
        },
        {
          $setWindowFields: {
            sortBy: { _id: 1 },
            output: {
              residents: {
                $sum: '$residents',
                window: { documents: ['unbounded', 'current'] },
              },
              visitors: {
                $sum: '$visitors',
                window: { documents: ['unbounded', 'current'] },
              },
            },
          },
        },
        {
          $project: {
            _id: false,
            time: '$_id',
            residents: true,
            visitors: true,
          },
        },
      ];

      log('Read', 'Location Occupancy Changes', query);

      return api.post('pipeline/intersections', { json, signal }).json();
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60,
    enabled: !isEmpty(query),
  });
}
