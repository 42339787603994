import { TagControl } from '@/components/controls';
import { useDocumentTitle } from '@/hooks';
import { startCase } from '@/utils';
import {
  Edit as EditIcon,
  LocationSearching as FollowIcon,
  Navigation as NavigationIcon,
  VolumeUp as VolumeUpIcon,
  VpnKey as VpnKeyIcon,
} from '@mui/icons-material';
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Stack,
  Table,
  TableBody,
  Tooltip,
  Typography,
} from '@mui/material';
import { amber, grey } from '@mui/material/colors';
import { format } from 'date-fns';
import {
  AlarmLight as AlarmLightIcon,
  CarLightHigh as HeadlightsFlashIcon,
} from 'mdi-material-ui';
import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { ItemAvatar } from './ItemAvatar';
import { ItemRows } from './ItemRows';

export function TelematicsBoxLiveItem({
  item,
  onFollowToggle,
  followedIdsByType,
}) {
  useDocumentTitle(
    ['IR3', 'Live', 'TelematicsBox', item?.imei].filter(Boolean).join(' | '),
  );
  const hideFollow = !item.lastPosition;
  const type = 'telematicsBoxes';
  const following = followedIdsByType?.[type]?.[item.id];

  const { headingDegrees, ignitionOn, sirensOn, beaconsOn, headlightsFlashOn } =
    item.mostRecentPoll;

  const navigate = useNavigate();

  const sections = {
    details: [
      { label: 'IMEI', value: item.imei },
      { label: 'Type', value: item.type },
      {
        label: 'Last ignition off time',
        value: item.lastIgnitionOffTime
          ? format(new Date(item.lastIgnitionOffTime), 'dd/MM/yyyy HH:mm:ss')
          : '-',
      },
      {
        label: 'Last poll time',
        value: item.mostRecentPoll?.time
          ? format(new Date(item.mostRecentPoll.time), 'dd/MM/yyyy HH:mm:ss')
          : '-',
      },
    ],
  };

  const title = item.imei;
  const link = `/resources/${type}/${item.id}`;

  return (
    <Card sx={{ m: 1 }}>
      <CardHeader
        avatar={<ItemAvatar item={item} type="telematicsBoxes" />}
        title={title}
        action={
          <Fragment>
            <Tooltip title="Edit">
              <IconButton onClick={() => navigate(link)} size="large">
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            {!hideFollow && (
              <Tooltip title={following ? 'Stop following' : 'Follow'}>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    onFollowToggle('telematicsBoxes', item.id);
                  }}
                >
                  <FollowIcon
                    titleAccess="Follow"
                    fontSize="small"
                    color={following ? 'primary' : 'disabled'}
                  />
                </IconButton>
              </Tooltip>
            )}
          </Fragment>
        }
      />

      <CardContent>
        <Stack direction="row" spacing={1}>
          <NavigationIcon
            // fromCharCode so degrees symbol doesn't stop chrome debugger mapping
            titleAccess={'Heading ' + headingDegrees + String.fromCharCode(176)}
            style={{
              transform: `rotate(${headingDegrees}deg)`,
              transformOrigin: ['50%', '50%'],
            }}
          />
          {ignitionOn !== undefined && (
            <VpnKeyIcon
              titleAccess={'Ignition ' + (ignitionOn ? 'on' : 'off')}
              htmlColor={ignitionOn ? amber[700] : grey[400]}
            />
          )}
          {sirensOn !== undefined && (
            <VolumeUpIcon
              titleAccess={'Siren ' + (sirensOn ? 'on' : 'off')}
              htmlColor={sirensOn ? amber[700] : grey[400]}
            />
          )}
          {beaconsOn !== undefined && (
            <AlarmLightIcon
              titleAccess={'Beacons ' + (beaconsOn ? 'on' : 'off')}
              htmlColor={beaconsOn ? amber[700] : grey[400]}
            />
          )}
          {headlightsFlashOn !== undefined && (
            <HeadlightsFlashIcon
              titleAccess={
                'Headlights flash ' + (headlightsFlashOn ? 'on' : 'off')
              }
              htmlColor={headlightsFlashOn ? amber[700] : grey[400]}
            />
          )}
        </Stack>
        <TagControl live item={item} type={type} sx={{ py: 2 }} label="Tags" />
        {Object.keys(sections)
          .filter((key) => sections[key] && sections[key].length > 0)
          .map((key) => (
            <Fragment key={key}>
              <Typography variant="subtitle2" color="textSecondary">
                {startCase(key)}
              </Typography>
              <Table size="small" sx={{ mt: 1, mb: 2 }}>
                <TableBody>{ItemRows(sections[key], item)}</TableBody>
              </Table>
            </Fragment>
          ))}
      </CardContent>
    </Card>
  );
}
