import { highlightsStateAtom } from '@/atoms';
import { useDocumentTitle, useGroups } from '@/hooks';
import { getGroupType } from '@/utils';
import { Autocomplete, Stack, TextField } from '@mui/material';
import { useAtom } from 'jotai';
import { UncheckedVehiclesCard } from '../UncheckedVehiclesCard';
import { AverageInStationTimeCard } from './AverageInStationTimeCard';
import { BoxAssignmentsCard } from './BoxAssignmentsCard';
import { CardAssignmentsCard } from './CardAssignmentsCard';
import { ExcessiveAccelerometerReadingsCard } from './ExcessiveAccelerometerReadingsCard';
import { GroupComparisonCard } from './GroupComparisonCard';
import { NonPollingVehiclesCard } from './NonPollingVehiclesCard';

export function Fleet() {
  useDocumentTitle('IR3 | Highlights | Fleet');
  const [{ force }, setState] = useAtom(highlightsStateAtom);
  const { data: groups } = useGroups({
    types: [getGroupType('FORCE')],
  });

  function handleForceChange(event, value) {
    setState((state) => ({ ...state, force: value }));
  }

  return (
    <Stack sx={{ p: 1 }} spacing={1}>
      <Stack
        direction="row"
        flexDirection="row-reverse"
        sx={{ width: 1, pt: 0.5 }}
      >
        <Autocomplete
          size="small"
          value={force}
          onChange={handleForceChange}
          options={groups.map(({ code, name }) => ({
            value: code,
            label: name,
          }))}
          renderInput={(params) => <TextField label="Force" {...params} />}
          isOptionEqualToValue={(option, value) =>
            (option.value ?? option) === (value.value ?? value)
          }
          renderOption={(props, option) => (
            <li {...props} key={option.value}>
              {option.label}
            </li>
          )}
          sx={{ minWidth: 200 }}
        />
      </Stack>
      <Stack spacing={1} direction="row">
        <Stack spacing={1}>
          <Stack spacing={1} direction="row">
            <CardAssignmentsCard groupCode={force?.value} />
            <BoxAssignmentsCard groupCode={force?.value} />
          </Stack>
          <NonPollingVehiclesCard groupCode={force?.value} />
          <UncheckedVehiclesCard groupCode={force?.value} />
        </Stack>
        <Stack spacing={1} sx={{ flexGrow: 1 }}>
          <GroupComparisonCard groupCode={force?.value} />
          <AverageInStationTimeCard groupCode={force?.value} />
          <ExcessiveAccelerometerReadingsCard groupCode={force?.value} />
        </Stack>
      </Stack>
    </Stack>
  );
}
