import {
  UPDATE_LIVE_ADVANCED_FILTERS,
  UPDATE_LIVE_LAYER_VISIBILITIES,
  UPDATE_LIVE_RESOURCES_IN_LOCATIONS,
  UPDATE_LIVE_SEARCHTEXTS,
  UPDATE_LIVE_SORTS,
} from '@/actions';
import { SearchBox } from '@/components/controls';
import { FilterField } from '@/components/fields';
import { useDebounce, usePrevious } from '@/hooks';
import { filterList, get, noop, startCase } from '@/utils';
import { dioStates, liveOptions } from '@/utils/config';
import { liveFilters } from '@/utils/constants';
import {
  ArrowUpward as ArrowUpwardIcon,
  FilterList as FilterListIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material';
import {
  Box,
  Collapse,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { dequal } from 'dequal';
import arrayMutators from 'final-form-arrays';
import _ from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useDispatch, useSelector } from 'react-redux';
import { FilterSummary } from '../FilterSummary';
import { pluralToSingularTypeMap } from '../constants';
import { filterFullyDefined } from '../utilities';

const {
  excludedSorts,
  includedSorts,
  excludedFilters,
  includedFilters,
  overriddenFilterLabels,
  vehiclesHaveCallsigns,
  mapLayerToggleOnList,
} = liveOptions;

const defaultSortOptionByType = {
  vehicles: { label: 'Registration Number', value: 'registrationNumber' },
  locations: { label: 'Name', value: 'name' },
  people: { label: 'Name', value: 'name' },
  incidents: { label: 'Type', value: 'type.name' },
  objectives: { label: 'Title', value: 'title' },
  tags: { label: 'Name', value: 'id' },
  callSigns: { label: 'Name', value: 'id' },
};

const DEFAULT_SORT_OPTION = { label: '<default>', value: 'id' };
const NO_SUGGESTION_TYPES = ['miles', 'date', 'datetime', 'number', 'duration'];

// dictionaries will be faster for constant lookups
function toLookup(dictOfArrays) {
  let result = {};
  Object.keys(dictOfArrays).forEach((key) => {
    result[key] = {};
    dictOfArrays[key].forEach((element) => (result[key][element] = true));
  });

  return result;
}

const excludedFiltersLookup = toLookup(excludedFilters);
const includedFiltersLookup = toLookup(includedFilters);
const excludedSortsLookup = toLookup(excludedSorts);
const includedSortsLookup = toLookup(includedSorts);

const allowed = (excluded, included) => (type, key) => {
  // it's allowed if not excluded and (there's no included list or it's included)
  return !excluded[type]?.[key] && (!included[type] || included[type]?.[key]);
};

const allowedSort = allowed(excludedSortsLookup, includedSortsLookup);
const allowedFilter = allowed(excludedFiltersLookup, includedFiltersLookup);

function advancedFiltersToFormData(advancedFilters) {
  // need to separate out filters in general vehicle to the sectioned
  // ones in the form e.g. vehicles [{field: 'driver.collarNumber', value: '123'}]
  // may need to go to vehicles,driver

  let initialValues = {};
  Object.keys(liveFilters).forEach((key) => {
    const filterConfig = liveFilters[key];
    if (liveFiltersSections[key]) {
      const sections = liveFiltersSections[key];
      initialValues[key] = [];

      Object.keys(sections).forEach((sectionKey) => {
        // find the existing advancedFilters that would come under
        // this section by matching the field (e.g. if the section
        // filters had ['regNo', 'driverSkillsArray'] there might
        // be {field: 'regNo', condition...}
        // or {field: 'driverSkillsArray', condition...} in the
        // advancedFilters) so use these as the initial values
        const filtersInSection =
          advancedFilters[key]?.filter((chosenFilter) =>
            sections[sectionKey].filters.some(
              (sectionFilterName) =>
                !!chosenFilter.field &&
                (sectionFilterName === chosenFilter.field ||
                  filterConfig[sectionFilterName]?.value ===
                    chosenFilter.field),
            ),
          ) || [];

        // are any dynamic ones in there too?
        // we'll know if areas is dynamic as it'll have isDictionary set
        // and we know which of the advancedFilters will be dynamic as they
        // will begin with the areas field/path i.e. reducedAreas.whatever
        // this'll work even when the data haven't been fully loaded and the
        // dynamic filters aren't available yet
        sections[sectionKey].filters.forEach((filterKey) => {
          if (
            filterConfig[filterKey] &&
            filterConfig[filterKey].isDictionary &&
            advancedFilters[key]
          ) {
            filtersInSection.push(
              ...advancedFilters[key].filter((f) =>
                f.field?.startsWith(filterConfig[filterKey].value),
              ),
            );
          }
        });

        initialValues[[key, sectionKey].join()] = filtersInSection;
      });
    } else {
      initialValues[key] = advancedFilters[key];
    }
  });

  return initialValues;
}

function valueToOption(value, filterDefinition) {
  return {
    // use a mapped option or a predefined option matching value or Start Case
    label:
      filterDefinition?.mapOption?.(value) ||
      filterDefinition?.options?.find((o) => o.value === value)?.label ||
      value,
    value,
  };
}

// addUnavailableProps will convert
// {label: 'Main BCU', value: 'main bcu'}
// to
// {label: 'Main BCU', value: 'main bcu',
//  unavailable: true, sx: 'unavailable'}
// if it's not possible
function addUnavailableProps(options, possibleDict) {
  return options.map((o) => {
    const unavailable = !!possibleDict && !possibleDict[o.value];

    return {
      ...o,
      unavailable,
      sx: unavailable ? 'unavailable' : undefined,
    };
  });
}

// arrayToOptions will convert ['main bcu', 'other bcu'] to
// [
//   {label: 'Main BCU', value: 'main bcu'},
//   {label: 'Other BCU', value: 'other bcu'}
// ]
function dataValuesToOptions(
  dataValues = [],
  possibleDict = {},
  filterSelections,
  filterDefinition,
) {
  // there may be previously selected values that are no longer in the data
  // e.g. select "STALEREG" then hide stale items. It won't be in the the array
  // in order to work out if possible or not so it will appear possible by default
  // we need to add these so we can correctly mark them as impossible
  const selectedValues =
    filterDefinition &&
    filterSelections?.find((s) => s.field === filterDefinition.value)?.value;

  if (selectedValues) {
    // add the selected values & make sure the end array is unique
    dataValues = [...new Set([...dataValues, ...selectedValues])];
  }

  return addUnavailableProps(
    dataValues.map(valueToOption, filterDefinition),
    possibleDict,
  );
}

function hideFiltersCallback(type) {
  return (filterName) => {
    const excluded =
      excludedFilters[type] && excludedFilters[type].includes(filterName);
    const included =
      !includedFilters[type] || includedFilters[type].includes(filterName);

    return included && !excluded;
  };
}

const liveFiltersSections = {
  vehicles: {
    vehicle: {
      label: 'Vehicle',
      filters: [
        'fleetNumber',
        'registrationNumber',
        'role',
        'type',
        'homeStation',
        // ...Object.keys(vehicleGroups).map((key) => `${key}`),
        'colour',
        'make',
        'model',
        'equipmentArray',
        'tagsArray',
        'groupCodes',
      ].filter(hideFiltersCallback('vehicles')),
    },
    inputs: {
      label: 'Telematics',
      filters: [
        ...Object.keys(dioStates),
        'malfunctionIndicatorLightOn',
        'emergencyEquipmentOn',
        'ignitionOn',
        'speedKilometresPerHour',
        'speeding',
        'lastIgnitionOffTime',
        'lastPollTime',
        'imei',
        'stale',
      ].filter(hideFiltersCallback('vehicles')),
    },
    driver: {
      label: 'Driver',
      filters: [
        'driverName',
        'driverCollarNumber',
        'driverCode',
        'driverRole',
        'driverCategory',
        'driverAreas',
        'driverSkillsArray',
        'driverIdentificationReference',
      ].filter(hideFiltersCallback('vehicles')),
    },
    assignment: {
      label: 'Assignments',
      filters: ['callSign', 'incident', 'status'].filter(
        hideFiltersCallback('vehicles'),
      ),
    },
    locations: {
      label: 'Locations',
      filters: ['locationNamesArray', 'locationTypesArray'].filter(
        hideFiltersCallback('vehicles'),
      ),
    },
  },
  locations: {
    location: {
      label: 'Location',
      filters: [
        'name',
        'type',
        'subtype',
        'tagsArray',
        'people',
        'vehicles',
        'groupCodes',
      ].filter(hideFiltersCallback('locations')),
    },
  },
  people: {
    person: {
      label: 'Person',
      filters: [
        'name',
        'code',
        'collarNumber',
        'role',
        'rank',
        'homeStation',
        'category',
        'skills',
        'driverKeys',
        'tagsArray',
        'groupCodes',
      ].filter(hideFiltersCallback('people')),
    },
    radio: {
      label: 'Radio',
      filters: ['radio', 'lastPollTime', 'emergencyButtonOn', 'stale'].filter(
        hideFiltersCallback('people'),
      ),
    },
    assignment: {
      label: 'Assignments',
      filters: [
        'callSign',
        'incident',
        'callSignStatus',
        'callSignCategory',
      ].filter(hideFiltersCallback('people')),
    },
    locations: {
      label: 'Locations',
      filters: ['locationNamesArray', 'locationTypesArray'].filter(
        hideFiltersCallback('people'),
      ),
    },
  },
  events: {
    event: {
      label: 'Events',
      filters: [
        'fleetNumber',
        'registrationNumber',
        'homeStation',
        'role',
        'type',
        'time',
        'locationNamesArray',
        'locationTypesArray',
        'tagsArray',
      ].filter(hideFiltersCallback('events')),
    },
  },
  incidents: {
    incident: {
      label: 'Incident',
      filters: [
        // 'number',
        'group',
        'type',
        'category',
        'responseCategory',
        'status',
        'closingCodes',
        'openedTime',
        'assignedTime',
        'attendedTime',
        'tagsArray',
        'date',
        'reference',
        'locationNamesArray',
        'locationTypesArray',
        'ward',
        'groupCodes',
      ].filter(hideFiltersCallback('incidents')),
    },
  },
  objectives: {
    objective: {
      label: 'Objective',
      filters: [
        'type',
        'identifier',
        'title',
        'startTime',
        'endTime',
        'status',
        'tagsArray',
        'wards',
      ].filter(hideFiltersCallback('objectives')),
    },
  },
  tags: {
    tag: {
      label: 'Tag',
      filters: ['id'].filter(hideFiltersCallback('tags')),
    },
  },
  callSigns: {
    callSign: {
      label: 'Call Sign',
      filters: ['id', 'status', 'incident'].filter(
        hideFiltersCallback('callSigns'),
      ),
    },
  },
  telematicsBoxes: {
    inputs: {
      label: 'Telematics',
      filters: [
        'imei',
        'type',
        'firstContactTime',
        'time',
        'lastIgnitionOffTime',
        'ignitionOn',
        'emergencyEquipmentOn',
        'beaconsOn',
        'sirensOn',
        'headlightsFlashOn',
        'speedKilometresPerHour',
      ].filter(hideFiltersCallback('telematicsBoxes')),
    },
    locations: {
      label: 'Locations',
      filters: ['locationNamesArray', 'locationTypesArray'].filter(
        hideFiltersCallback('telematicsBoxes'),
      ),
    },
  },
  radios: {
    radio: {
      label: 'Radio',
      filters: ['ssi', 'type'].filter(hideFiltersCallback('radiios')),
    },
    locations: {
      label: 'Locations',
      filters: ['locationNamesArray', 'locationTypesArray'].filter(
        hideFiltersCallback('radios'),
      ),
    },
  },
};

export function FilterControl({
  sx,
  type,
  showFilter,
  filters,
  fullList,
  onFilterToggle,
  filteredListLength,
}) {
  const sorts = useSelector((state) => state.live.sorts);
  const sort =
    sorts[type] || defaultSortOptionByType[type] || DEFAULT_SORT_OPTION;

  const searchTexts = useSelector((state) => state.live.searchTexts);
  const searchText = searchTexts[type] || '';

  // this is now searchTextLOCAL because props is now destructured in function
  // and this component needs a local search text to change in response to user
  // input and a debounced external one when it is supposed to trigger filtering
  const [searchTextLocal, setSearchTextLocal] = useState(searchText || '');

  // 5 setXXX will result in 5 renders, condense to one to improve perf
  const [settings, setSettings] = useState({
    suggestions: {},
    sortBy: sorts[type] || defaultSortOptionByType[type] || DEFAULT_SORT_OPTION,
    sortDesc: sorts[type]?.desc,
    sortOptions: [defaultSortOptionByType[type] || DEFAULT_SORT_OPTION],
  });

  const currListLength = (fullList || []).length;
  const prevListLength = usePrevious(currListLength);
  const prevType = usePrevious(type);
  const filteredInIds = useSelector(
    (state) => state.live.filteredInIdsByType[type],
  );
  const prevFilteredInIds = usePrevious(filteredInIds);
  const advancedFilters = useSelector((state) => state.live.advancedFilters);
  const prevAdvancedFilters = usePrevious(advancedFilters);

  const selectedLiveViewKey = useSelector(
    (state) => state.live.selectedLiveViewKey,
  );

  const layerVisibilities = useSelector(
    (state) => state.live.layerVisibilities,
  );
  const STALE = 'stale';
  const showStale = layerVisibilities[STALE];
  const prevShowStale = usePrevious(showStale);
  const dispatch = useDispatch();

  function needsSuggestions(type) {
    return !NO_SUGGESTION_TYPES.some((t) => t === type);
  }

  function updateSorts(change) {
    dispatch({
      type: UPDATE_LIVE_SORTS,
      payload: {
        ...sorts,
        [type]: {
          ...(sorts[type] || {}),
          ...change,
        },
      },
    });
  }

  function handleSortByChange(event) {
    setSettings({ ...settings, sortBy: event.target.value });
    updateSorts({ path: event.target.value });
  }

  function handleSortToggle() {
    setSettings({ ...settings, sortDesc: !settings.sortDesc });
    updateSorts({ desc: !settings.sortDesc });
  }

  // it seems I have to do this every time the list changes, surely
  // there's some way to improve this frequent expensive comp: TODO JL
  useEffect(() => {
    // it is possible that all the filters would need to be redone each
    // time the resource list changes at all, however for performance we
    // will try to limit this to a few triggers, an update is needed if:
    const updateNeeded =
      // - there are more or less items
      currListLength !== prevListLength ||
      // - the type changes (all options need updating)
      prevType !== type ||
      // - a resource changes being filtered in/out (changes possible options)
      prevFilteredInIds !== filteredInIds ||
      // - a selection changes (changes possible options)
      prevAdvancedFilters !== advancedFilters;

    // console.log('Full  list changed');
    let allOptionsByFilter = updateNeeded ? {} : settings.suggestions;
    let sortOptions = updateNeeded
      ? [defaultSortOptionByType[type] || DEFAULT_SORT_OPTION]
      : settings.sortOptions;
    let filterDefinitions = liveFilters[type];

    if (!filterDefinitions) {
      return;
    }

    // only use the categorical multiselect filters for checking possible options
    // i.e. don't get all the options for mileage for example
    let filterSelections = (advancedFilters[type] || []).filter(({ field }) => {
      if (!field) {
        return false;
      }

      const rootPath = field.split('.')[0];
      const definitionKey = Object.keys(filterDefinitions).find(
        (definitionKey) =>
          filterDefinitions[definitionKey].value === rootPath ||
          filterDefinitions[definitionKey].value === field,
      );

      return !!definitionKey;
    });

    // to only allow possible options we need the currently filtered list
    const completeFilteredList = filterList(
      fullList,
      null,
      null,
      filterSelections,
    );

    // if this filter has some selections it shouldn't limit its own options
    // (e.g. selecting Ford shouldn't limit Make to just Ford) so if needed,
    // get the possible options as if that particular filter wasn't set at all
    // and any other filter selections are unchanged
    function refilterExcluding(fieldName) {
      // if there's no selections for this filter anyways return the full list
      if (!filterSelections.some((f) => f.field === fieldName)) {
        return completeFilteredList;
      }

      const filterSelectionsExcludingThisOne = filterSelections.filter(
        (f) => f.field !== fieldName,
      );
      return filterList(fullList, null, null, filterSelectionsExcludingThisOne);
    }

    function addSortOption(filterDefinition, filterKey, label, value) {
      const shouldInclude =
        !filterDefinition.excludeSort && allowedSort(type, filterKey);

      if (shouldInclude) {
        // default to the filter name and value
        label = (!!label && label) || filterDefinition.name;
        value = value || filterDefinition.value;

        // all sort options are in the one dropdown and there may be a prefix so
        // vehicle role and driver role don't have the same label e.g. 'Driver Role'
        label = [
          filterDefinition.prefix,
          overriddenFilterLabels[label] || startCase(label),
        ]
          .filter(Boolean)
          .join(' ');

        // if it's not added already, add it...
        if (!sortOptions.find((s) => s.value === value)) {
          sortOptions.push({
            label,
            value,
          });
        }
      }
    }

    // go through each filter definition and get all the options and the
    // possible options for it. Some may be dynamic like areas or arrays
    // like skills
    let settingsChanged = updateNeeded;
    Object.keys(filterDefinitions).forEach((filterKey) => {
      const filterDefinition = filterDefinitions[filterKey];

      // if an update is needed or this filter changes often, get options
      if (updateNeeded || filterDefinition.changeful) {
        const keysToOptions = (obj = {}, possibleDict) => {
          return dataValuesToOptions(
            Object.keys(obj),
            possibleDict,
            filterSelections,
            filterDefinition,
          );
        };

        // filters like speed don't need suggestions so just add a sort option
        if (!needsSuggestions(filterDefinition.type)) {
          addSortOption(filterDefinition, filterKey);
          return; // skip the rest
        }

        // some filters are dynamic & not set by the config e.g. areas. each item
        // in the list might have an array of areas like
        //   areas: [{type: 'bcu', name: 'Main BCU'}, {type: 'lpu', name: 'LPU A'}]
        // !!! which I expect to be transformed into something like
        //   reducedAreas: {bcu: 'Main BCU', lpu: 'LPU A'}
        // for the above bcu and lpu are new separate dynamic filters with options
        // assembled from all items. We should end with suggestions like
        // 'reducedAreas.bcu': [{label: 'Main BCU', value: 'Main BCU', {...}]
        if (filterDefinition.isDictionary) {
          // go through the full list of data and get all the types and their options
          const dynamicFiltersAllOptions = fullList.reduce(
            (dynamicFilters, item) => {
              // get the dictionary e.g.: get(someVehicle, 'reducedAreas')
              const dict = get(item, filterDefinition.value);
              if (dict) {
                // for each of ['bcu', 'lpu', ...]
                Object.keys(dict)
                  .filter((key) =>
                    allowedFilter(type, filterDefinition.value + '.' + key),
                  )
                  .forEach((dynamicFilterKey) => {
                    // if the type isn't there already, add a blank one
                    // dynamicFilterKey here will be bcu or lpu etc.
                    dynamicFilters[dynamicFilterKey] = dynamicFilters[
                      dynamicFilterKey
                    ] || {
                      path: filterDefinition.value + '.' + dynamicFilterKey,
                      options: {},
                    };

                    // add a dictionary item for the name i.e. 'Main BCU' or 'LPU A'
                    [dict[dynamicFilterKey]].flat().forEach((option) => {
                      dynamicFilters[dynamicFilterKey].options[option.trim()] =
                        true;
                    });
                  });
              }

              return dynamicFilters;
            },
            {},
          );

          // dynamicOptions now looks something like this:
          // these are the isDictionary dynamic type
          // dynamicOptions = {
          //    bcu: {
          //      path: 'reducedAreas.bcu',
          //      options: {'Main BCU': true, 'Other BCU': true}
          //    },
          //    lpu: {path: 'reducedAreas.lpu', options: {'LPU A': true}}
          // };

          // to get the possible options we need to go through each and refilter in
          // case that dynamic filter has some options selected e.g. if 'Main BCU'
          // is selected, it shouldn't prohibit 'Other BCU' from being selected
          let dynamicFiltersPossibleOptions = {};
          Object.keys(dynamicFiltersAllOptions).forEach((dynamicFilterKey) => {
            const dynamicFilter = dynamicFiltersAllOptions[dynamicFilterKey];
            const field = dynamicFilter.path;
            let filteredList = refilterExcluding(field);

            // TODO JL refactor all this stuff, lots of similarities with the alloptions
            // one, and the names of keys and dicts are awful!
            const possibleOptionsDict = filteredList.reduce((options, item) => {
              const dict = get(item, filterDefinition.value);
              if (dict) {
                [dict[dynamicFilterKey]].flat().forEach((option) => {
                  if (option) {
                    options[option.trim()] = true;
                  }
                });
              }

              return options;
            }, {});

            dynamicFiltersPossibleOptions[dynamicFilterKey] = {
              path: dynamicFilter.path,
              options: possibleOptionsDict,
            };
          });

          // for each dynamic filter (bcu, lpu), convert the keys to an option list,
          // and add it as a sort option
          Object.keys(dynamicFiltersAllOptions).forEach((dynamicFilterKey) => {
            const dynamicFilter = dynamicFiltersAllOptions[dynamicFilterKey];

            // change the dynamicFilter options dictionary to a list of options
            // before adding to the suggestions e.g.
            //    bcu: {
            //      path: 'reducedAreas.bcu',
            //      options: {'Main BCU': true, 'Other BCU': true}
            //    },
            // becomes
            //  {'reducedAreas.bcu':
            //    [{label: 'Main BCU', value: 'Main BCU', {label: 'Other BCU', ...}]
            //  }
            const options = keysToOptions(
              dynamicFiltersAllOptions[dynamicFilterKey].options,
              dynamicFiltersPossibleOptions[dynamicFilterKey].options,
            );

            settingsChanged =
              settingsChanged ||
              !dequal(options, allOptionsByFilter[dynamicFilter.path]);

            allOptionsByFilter[dynamicFilter.path] = options;

            addSortOption(
              filterDefinition,
              filterKey,
              dynamicFilterKey,
              dynamicFilter.path,
            );
          });
        } else if (filterDefinition.isArray) {
          // go through the full list of data and get all the types and options
          const filterOptionsFromArrays = (list) => {
            return list.reduce((options, item) => {
              const array = get(item, filterDefinition.value);
              if (array && array.length > 0) {
                array.forEach((option) => {
                  options = options || {};
                  options[option] = true;
                });
              }

              return options;
            }, {});
          };

          const allOptionsDict = filterOptionsFromArrays(fullList);

          let filteredList = refilterExcluding(filterDefinition.value);
          const possibleOptionsDict = filterOptionsFromArrays(filteredList);

          // dynamicOptions now looks something like this:
          // dynamicOptions = {
          //   '4X4 driving on road': 'driverSkillsArray',
          //   '4X4 driving off road': 'driverSkillsArray'
          // };

          // convert the keys to suggestions
          const options = keysToOptions(allOptionsDict, possibleOptionsDict);

          settingsChanged =
            settingsChanged || !dequal(options, allOptionsByFilter[filterKey]);

          allOptionsByFilter[filterKey] = options;

          // none of the array types are sortable
        } else {
          // for each item in the list, get the filterDefinition.value e.g. registration
          // filter out any blank ones and only get the unique ones
          const optionsFromList = (list, possible) => {
            return dataValuesToOptions(
              _.chain(
                list.map((item) => get(item, filterDefinition.value)).sort(),
              )
                .filter((value) => typeof value !== 'undefined' && value !== '')
                .uniq()
                .value(),
              possible,
              filterSelections,
              filterDefinition,
            );
          };

          // which values are possible with the current filters excluding this one?
          const possible = refilterExcluding(filterDefinition.value).reduce(
            (dict, item) => {
              let option = get(item, filterDefinition.value);
              dict[option] = true;
              return dict;
            },
            {},
          );

          // for standard (not dictionary or array) filters they could have
          // predefined options so use these instead of trawling the data
          // make sure to mark any unavailable ones as such
          const predefinedOptions =
            filterDefinition.options &&
            addUnavailableProps(filterDefinition.options, possible);

          const options =
            predefinedOptions || optionsFromList(fullList, possible);

          settingsChanged =
            settingsChanged || !dequal(options, allOptionsByFilter[filterKey]);

          allOptionsByFilter[filterKey] = options;

          addSortOption(filterDefinition, filterKey);
        }
      } // end if updateNeeded || filterDefinition.changeful
    }); // end forEach filterDefinition

    if (settingsChanged) {
      // console.log("filters etc. changed!!");

      setSettings({
        suggestions: allOptionsByFilter,
        sortOptions: _.sortBy(sortOptions, 'label'),
        sortBy: sort.path,
        sortDesc: sort.desc,
      });
    }
  }, [
    fullList,
    type,
    prevType,
    currListLength,
    prevListLength,
    sort,
    advancedFilters,
    prevAdvancedFilters,
    filteredInIds,
    prevFilteredInIds,
    settings,
  ]);

  // debounce search change, don't do a search on every char, only when finished typing
  const debouncedSearchTerm = useDebounce(searchTextLocal, 500);
  const prevDebouncedSearchTerm = usePrevious(debouncedSearchTerm);
  useEffect(() => {
    if (debouncedSearchTerm !== prevDebouncedSearchTerm) {
      dispatch({
        type: UPDATE_LIVE_SEARCHTEXTS,
        payload: { ...searchTexts, [type]: searchTextLocal },
      });
    }
  }, [
    debouncedSearchTerm,
    prevDebouncedSearchTerm,
    dispatch,
    searchTextLocal,
    searchTexts,
    type,
  ]);

  // reload filter list in locations when show/hide stale selected for advance filters (people and vehicles)
  useEffect(() => {
    const hasPeopleOrLocationInFilter = advancedFilters.locations?.some(
      (o) => o.field === 'people' || o.field === 'vehicles',
    );
    if (
      showStale !== prevShowStale &&
      type === 'locations' &&
      hasPeopleOrLocationInFilter
    ) {
      dispatch({
        type: UPDATE_LIVE_ADVANCED_FILTERS,
        payload: { ...advancedFilters },
      });
    }
  }, [dispatch, showStale, prevShowStale, advancedFilters, type]);

  useEffect(() => {
    if (prevType !== type && type === 'locations') {
      dispatch({
        type: UPDATE_LIVE_RESOURCES_IN_LOCATIONS,
      });
    }
  }, [dispatch, type, prevType]);

  function handleSearchChange(event) {
    const term = event.target.value;

    setSearchTextLocal(term);
  }

  useEffect(() => {
    if (type !== prevType) {
      setSearchTextLocal(searchText);
    }
  }, [searchText, type, prevType]);

  function handleVisibilityToggle() {
    dispatch({
      type: UPDATE_LIVE_LAYER_VISIBILITIES,
      payload: {
        ...layerVisibilities,
        [type]: !(type in layerVisibilities ? layerVisibilities[type] : true),
      },
    });
  }

  const styles = {
    unavailable: {
      textDecoration: 'line-through',
    },
  };

  function filterToPossible(options, state) {
    let possible = [];
    let impossible = [];

    options.forEach((o) => {
      if (!o.unavailable) {
        possible.push(o);
      } else {
        impossible.push(o);
      }
    });

    options = _.orderBy(possible, 'label').concat(
      _.orderBy(impossible, 'label'),
    );

    if (state.inputValue) {
      const match = state.inputValue.toLowerCase();
      options = options.filter(
        (o) => o.value.toLowerCase().indexOf(match) !== -1,
      );
    }

    return options;
  }

  function getFilterFields(name, filtersDict, form) {
    let allFilters = {};
    Object.keys(filtersDict).forEach((key) => {
      const filter = filtersDict[key];
      const filterConfig = liveFilters[type][key];

      // if any of the filters are a dictionary type (e.g. areas) replace it
      // with the known filters
      if (filterConfig?.isDictionary) {
        // find all the keys of options that start with this filter's path
        // e.g. for areas, find all suggestions that start with 'reducedAreas'
        const dynamicFilterPaths = Object.keys(settings.suggestions).filter(
          (suggestionsKey) => suggestionsKey.startsWith(filterConfig.value),
        );
        dynamicFilterPaths.forEach((dynamicFilterPath) => {
          // the key is used as the path to the property, i.e. field
          const suggestions = settings.suggestions[dynamicFilterPath];
          const dynamicFilterName = dynamicFilterPath.split('.').pop();

          allFilters[dynamicFilterPath] = {
            label:
              overriddenFilterLabels[dynamicFilterName] ||
              startCase(dynamicFilterName),
            type: 'multiselect',
            values: suggestions,
            filterOptions: filterToPossible,
            styles,
            onlyEqual: filterConfig?.onlyEqual,
            parse: filterConfig?.parse,
            format: filterConfig?.format,
            anyOption: true,
            noneOption: true,
          };
        });
      } else {
        const suggestions = settings.suggestions[key];

        allFilters[filter.value] = {
          label: overriddenFilterLabels[filter.name] || filter.name,
          type: filter.type || 'multiselect',
          values: suggestions,
          filterOptions: filterToPossible,
          styles,
          unit: filter.unit,
          onlyEqual: filterConfig?.onlyEqual,
          parse: filterConfig?.parse,
          format: filterConfig?.format,
          anyOption: true,
          noneOption: true,
          optionName: filter.optionName,
        };
      }
    });

    // there may be previously selected values for dynamic data that no longer exist
    // e.g. { field: 'reducedAreas.bcu', value: ['STALEBCU'] } when hiding stale
    // add dummy filters so we can keep the value but mark it as impossible
    const selectedValues = form.getState()?.values?.[name] || [];
    selectedValues.forEach(({ field, value }) => {
      if (!!field && !!value && !allFilters[field]) {
        const subtype = field.split('.').pop(); // get bcu for reducedAreas.bcu

        allFilters[field] = {
          label: overriddenFilterLabels[subtype] || subtype,
          type: 'multiselect',
          values: dataValuesToOptions(value),
          filterOptions: filterToPossible,
          styles,
          anyOption: true,
          noneOption: true,
        };
      }
    });

    return (
      <FieldArray
        // label={key}
        name={name}
        filters={allFilters}
        component={FilterField}
        clearValue={form.mutators.clearValue}
      />
    );
  }

  function getFilterSections(type, form) {
    // does this type have named sections or not?
    if (liveFiltersSections[type]) {
      return Object.keys(liveFiltersSections[type]).map((sectionKey, index) => {
        const first = index === 0;
        const section = liveFiltersSections[type][sectionKey];
        const filtersForSection = Object.fromEntries(
          section.filters
            .map((key) =>
              liveFilters[type][key]
                ? [key, liveFilters[type][key]]
                : undefined,
            )
            .filter(Boolean),
        );

        return (
          <Fragment key={sectionKey}>
            {Object.keys(filtersForSection).length > 0 && (
              <Fragment>
                {!first && <Divider />}
                <Typography
                  sx={{ mt: 1 }}
                  variant="subtitle2"
                  color="textSecondary"
                >
                  {section.label}
                </Typography>
                <Box id={section.label}>
                  {getFilterFields(
                    [type, sectionKey].join(),
                    filtersForSection,
                    form,
                  )}
                </Box>
              </Fragment>
            )}
          </Fragment>
        );
      });
    } else {
      // if no named section just generate all the filter fields in one
      return (
        <Fragment>
          <Typography sx={{ mt: 1 }} variant="subtitle2" color="textSecondary">
            {startCase(pluralToSingularTypeMap[type])}
          </Typography>
          {getFilterFields(type, liveFilters[type], form)}
        </Fragment>
      );
    }
  }

  function formDataToAdvancedFilters(data) {
    // filter sections have a key that looks like vehicle,driver
    // need to get all the filter values out of these and into
    // the general type e.g. vehicle,driver.name --> vehicle.name
    return Object.keys(data).reduce((filters, key) => {
      let typeKey = key.split(',')[0];
      filters[typeKey] = [...(filters[typeKey] || []), ...(data[key] || [])];

      return filters;
    }, {});
  }

  function removeFiltersWithNoValue(filters) {
    Object.keys(filters).forEach((type) => {
      filters[type] = filters[type].filter(filterFullyDefined);

      // more correct but if a view has an empty filter [] it won't match
      // and will always look modified when it is just applied *Some View
      // if (filters[type].length  === 0) {
      //   delete filters[type];
      // }
    });

    return filters;
  }

  return (
    <Box sx={sx}>
      <Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', height: 1 }}>
          <Stack direction="row" sx={{ p: 1 }} spacing={1} alignItems="center">
            <SearchBox
              value={searchTextLocal}
              onChange={handleSearchChange}
              sx={{ flexGrow: 1 }}
              count={
                filteredListLength === currListLength
                  ? `${currListLength}`
                  : `${filteredListLength}/${currListLength}`
              }
            />
            {filters && (
              <Tooltip title={showFilter ? 'Hide filter' : 'Show filter'}>
                <IconButton onClick={onFilterToggle}>
                  <FilterListIcon
                    color={showFilter ? 'primary' : 'inherit'}
                    fontSize="inherit"
                  />
                </IconButton>
              </Tooltip>
            )}
            {mapLayerToggleOnList && (
              <Tooltip
                title={
                  layerVisibilities[type] === false
                    ? 'Show layer'
                    : 'Hide layer'
                }
              >
                <IconButton onClick={handleVisibilityToggle} size="small">
                  {layerVisibilities[type] === false ? (
                    <VisibilityOffIcon fontSize="inherit" />
                  ) : (
                    <VisibilityIcon fontSize="inherit" />
                  )}
                </IconButton>
              </Tooltip>
            )}
          </Stack>
          <Collapse in={showFilter} timeout="auto" unmountOnExit>
            <Stack sx={{ p: 1 }} spacing={1}>
              <TextField
                size="small"
                select
                fullWidth
                label="Sort by"
                value={settings.sortBy || ''}
                onChange={handleSortByChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <Tooltip
                        title={settings.sortDesc ? 'Descending' : 'Ascending'}
                      >
                        <IconButton
                          sx={(theme) => ({
                            transform: settings.sortDesc
                              ? 'rotate(180deg)'
                              : 'rotate(0deg)',
                            transition: theme.transitions.create('transform', {
                              duration: theme.transitions.duration.shortest,
                            }),
                          })}
                          onClick={handleSortToggle}
                          size="small"
                        >
                          <ArrowUpwardIcon fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              >
                {settings.sortOptions.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>
            <Stack sx={{ px: 1 }} spacing={1}>
              <Divider>
                <Typography variant="caption" color="textSecondary">
                  Filters
                </Typography>
              </Divider>
              <Form
                key={selectedLiveViewKey}
                initialValues={advancedFiltersToFormData(advancedFilters)}
                onSubmit={noop}
                keepDirtyOnReinitialize={true}
                mutators={{
                  resetFilter: ({ 1: name }, state, { changeValue }) => {
                    function wipeSelections(filter) {
                      delete filter.value;
                      filter.condition = '$eq';
                      return filter;
                    }

                    changeValue(state, name, wipeSelections);
                  },
                  // setValue: ([name, value], state, { changeValue }) => {
                  //   console.log('setValue');
                  //   changeValue(state, name, () => value);
                  // },
                  ...arrayMutators,
                }}
                render={({ handleSubmit, form }) => {
                  return (
                    <form id="filters" onSubmit={handleSubmit}>
                      {Object.keys(liveFilters)
                        .filter((k) => k === type)
                        .map((filterType) => (
                          <Box key={filterType}>
                            {getFilterSections(filterType, form)}
                          </Box>
                        ))}
                      <FormSpy
                        subscription={{ values: true }}
                        onChange={(state) => {
                          const newAdvancedFilters = removeFiltersWithNoValue(
                            formDataToAdvancedFilters(state.values),
                          );

                          if (!dequal(newAdvancedFilters, advancedFilters)) {
                            dispatch({
                              type: UPDATE_LIVE_ADVANCED_FILTERS,
                              payload: newAdvancedFilters,
                            });
                          }
                        }}
                      />
                    </form>
                  );
                }}
              />
              {type === 'callSigns' && (
                <Fragment>
                  <Divider />
                  <Typography
                    sx={{ mt: 1 }}
                    variant="subtitle2"
                    color="textSecondary"
                  >
                    Related Filters
                  </Typography>
                  <FilterSummary
                    filters={{
                      ...(vehiclesHaveCallsigns
                        ? { vehicles: advancedFilters.vehicles }
                        : {}),
                      people: advancedFilters.people,
                    }}
                    hideEmpty={false}
                  />
                </Fragment>
              )}
            </Stack>
            <Divider />
          </Collapse>
        </Box>
      </Box>
    </Box>
  );
}
