import { getTextWidth } from '@/utils';
import {
  DoNotDisturb as DoNotDisturbIcon,
  DoneAll as DoneAllIcon,
  Done as DoneIcon,
  RemoveDone as RemoveDoneIcon,
} from '@mui/icons-material';
import {
  Autocomplete,
  Popper,
  TextField,
  Typography,
  autocompleteClasses,
  styled,
} from '@mui/material';
import { Fragment, createContext, forwardRef, useContext } from 'react';
import { FixedSizeList } from 'react-window';
import { Adornment } from '..';

const LISTBOX_PADDING = 8; // px

const conditions = [
  {
    label: <DoneIcon fontSize="inherit" />,
    value: 'includes',
    title: 'Includes',
  },
  {
    label: <DoNotDisturbIcon fontSize="inherit" />,
    value: 'excludes',
    title: 'Excludes',
  },
  {
    label: <DoneAllIcon fontSize="inherit" />,
    value: 'any',
    title: 'Any',
  },
  {
    label: <RemoveDoneIcon fontSize="inherit" />,
    value: 'none',
    title: 'None',
  },
];

// function DynamicWidthPopper({ style: { width, ...style }, ...props }) {
//   return <Popper {...props} style={style} placement="bottom-start" />;
// }

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

const getComputedWidthPopper = (options, font, padding) =>
  function ComputedWidthPopper({ style: { width, ...style }, ...props }) {
    const computedWidth =
      Math.ceil(
        Math.max(
          ...(options || []).map((option) =>
            getTextWidth(
              option.label ?? options.find((o) => o.value === option).label,
              font,
            ),
          ),
        ),
      ) + padding;

    return (
      <StyledPopper
        {...props}
        style={{ width: Math.max(computedWidth, width), ...style }}
        placement="bottom-start"
      />
    );
  };

function renderRow({ data, index, style }) {
  const [{ key, ...props }, option] = data[index];

  return (
    <Typography
      component="li"
      key={key}
      {...props}
      noWrap
      style={{
        ...style,
        top: style.top + LISTBOX_PADDING,
      }}
    >
      {option.label}
    </Typography>
  );
}

const OuterElementContext = createContext({});

const OuterElementType = forwardRef(function OuterElementType(props, ref) {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

const VirtualisedListbox = forwardRef(function VirtualisedListbox(
  { children, ...other },
  ref,
) {
  const itemCount = children.length;
  const itemSize = 36;
  const height = Math.min(8, itemCount) * itemSize;

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <FixedSizeList
          itemData={children}
          height={height + 2 * LISTBOX_PADDING}
          width="100%"
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={itemSize}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </FixedSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

export function SelectFilterField({
  value,
  onChange,
  multiple,
  options,
  size,
  ...props
}) {
  const empty = multiple ? [] : null;
  const { condition, selection } = value ?? {
    condition: 'includes',
    selection: empty,
  };
  const disabled = ['any', 'none'].includes(condition);

  function handleSelectionChange(event, selection) {
    onChange({
      condition,
      selection: disabled
        ? empty
        : multiple
          ? selection.map((option) => option.value ?? option)
          : selection?.value,
    });
  }

  function handleConditionChange(condition) {
    const disabled = ['any', 'none'].includes(condition);
    onChange({ condition, selection: disabled ? empty : selection });
  }

  return (
    <Autocomplete
      value={selection ?? empty}
      onChange={handleSelectionChange}
      multiple={multiple}
      size={size}
      options={options}
      disabled={disabled}
      getOptionLabel={(option) =>
        option.label ?? options.find((o) => o.value === option).label
      }
      isOptionEqualToValue={(option, value) => option.value === value}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={
            disabled
              ? conditions.find((c) => c.value === condition)?.title
              : undefined
          }
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <Fragment>
                <Adornment
                  value={condition}
                  values={conditions}
                  onChange={handleConditionChange}
                  position="start"
                  sx={{ pl: 0.5, pt: 0.75 }}
                />
                {params.InputProps.startAdornment}
              </Fragment>
            ),
          }}
          {...props}
        />
      )}
      PopperComponent={getComputedWidthPopper(options, '16px Roboto', 44)}
      disableListWrap
      ListboxComponent={VirtualisedListbox}
      renderOption={(props, option) => [props, option]}
    />
  );
}
